(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === elm$core$Basics$EQ ? 0 : ord === elm$core$Basics$LT ? -1 : 1;
	}));
});



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = elm$core$Set$toList(x);
		y = elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = elm$core$Dict$toList(x);
		y = elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = elm$core$Dict$toList(x);
		y = elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (!x.$)
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? elm$core$Basics$LT : n ? elm$core$Basics$GT : elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}



// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region._.H === region.ag.H)
	{
		return 'on line ' + region._.H;
	}
	return 'on lines ' + region._.H + ' through ' + region.ag.H;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800)
			+
			String.fromCharCode(code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? elm$core$Maybe$Nothing
		: elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? elm$core$Maybe$Just(n) : elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




/**_UNUSED/
function _Json_errorToString(error)
{
	return elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

var _Json_decodeInt = { $: 2 };
var _Json_decodeBool = { $: 3 };
var _Json_decodeFloat = { $: 4 };
var _Json_decodeValue = { $: 5 };
var _Json_decodeString = { $: 6 };

function _Json_decodeList(decoder) { return { $: 7, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 8, b: decoder }; }

function _Json_decodeNull(value) { return { $: 9, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 10,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 11,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 12,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 13,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 14,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 15,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 3:
			return (typeof value === 'boolean')
				? elm$core$Result$Ok(value)
				: _Json_expecting('a BOOL', value);

		case 2:
			if (typeof value !== 'number') {
				return _Json_expecting('an INT', value);
			}

			if (-2147483647 < value && value < 2147483647 && (value | 0) === value) {
				return elm$core$Result$Ok(value);
			}

			if (isFinite(value) && !(value % 1)) {
				return elm$core$Result$Ok(value);
			}

			return _Json_expecting('an INT', value);

		case 4:
			return (typeof value === 'number')
				? elm$core$Result$Ok(value)
				: _Json_expecting('a FLOAT', value);

		case 6:
			return (typeof value === 'string')
				? elm$core$Result$Ok(value)
				: (value instanceof String)
					? elm$core$Result$Ok(value + '')
					: _Json_expecting('a STRING', value);

		case 9:
			return (value === null)
				? elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 5:
			return elm$core$Result$Ok(_Json_wrap(value));

		case 7:
			if (!Array.isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 8:
			if (!Array.isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 10:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return (elm$core$Result$isOk(result)) ? result : elm$core$Result$Err(A2(elm$json$Json$Decode$Field, field, result.a));

		case 11:
			var index = decoder.e;
			if (!Array.isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return (elm$core$Result$isOk(result)) ? result : elm$core$Result$Err(A2(elm$json$Json$Decode$Index, index, result.a));

		case 12:
			if (typeof value !== 'object' || value === null || Array.isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!elm$core$Result$isOk(result))
					{
						return elm$core$Result$Err(A2(elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return elm$core$Result$Ok(elm$core$List$reverse(keyValuePairs));

		case 13:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return elm$core$Result$Ok(answer);

		case 14:
			var result = _Json_runHelp(decoder.b, value);
			return (!elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 15:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if (elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return elm$core$Result$Err(elm$json$Json$Decode$OneOf(elm$core$List$reverse(errors)));

		case 1:
			return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!elm$core$Result$isOk(result))
		{
			return elm$core$Result$Err(A2(elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return elm$core$Result$Ok(toElmValue(array));
}

function _Json_toElmArray(array)
{
	return A2(elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 3:
		case 2:
		case 4:
		case 6:
		case 5:
			return true;

		case 9:
			return x.c === y.c;

		case 7:
		case 8:
		case 12:
			return _Json_equality(x.b, y.b);

		case 10:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 11:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 13:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 14:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 15:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2(elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.a1,
		impl.be,
		impl.bc,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2(elm$json$Json$Decode$map, func, handler.a)
				:
			A3(elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		q: func(record.q),
		aa: record.aa,
		Z: record.Z
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		(key !== 'value' || key !== 'checked' || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		value
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		value
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.q;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.aa;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.Z) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			var oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			var newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}



// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.a1,
		impl.be,
		impl.bc,
		function(sendToApp, initialModel) {
			var view = impl.bg;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.a1,
		impl.be,
		impl.bc,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.K && impl.K(sendToApp)
			var view = impl.bg;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.aT);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.bd) && (_VirtualDom_doc.title = title = doc.bd);
			});
		}
	);
});



// ANIMATION


var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.a6;
	var onUrlRequest = impl.a7;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		K: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.download)
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.aA === next.aA
							&& curr.ap === next.ap
							&& curr.ax.a === next.ax.a
						)
							? elm$browser$Browser$Internal(next)
							: elm$browser$Browser$External(href)
					));
				}
			});
		},
		a1: function(flags)
		{
			return A3(impl.a1, flags, _Browser_getUrl(), key);
		},
		bg: impl.bg,
		be: impl.be,
		bc: impl.bc
	});
}

function _Browser_getUrl()
{
	return elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return elm$core$Result$isOk(result) ? elm$core$Maybe$Just(result.a) : elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { a$: 'hidden', G: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { a$: 'mozHidden', G: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { a$: 'msHidden', G: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { a$: 'webkitHidden', G: 'webkitvisibilitychange' }
		: { a$: 'hidden', G: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail(elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		aH: _Browser_getScene(),
		aP: {
			T: _Browser_window.pageXOffset,
			U: _Browser_window.pageYOffset,
			E: _Browser_doc.documentElement.clientWidth,
			z: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		E: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		z: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			aH: {
				E: node.scrollWidth,
				z: node.scrollHeight
			},
			aP: {
				T: node.scrollLeft,
				U: node.scrollTop,
				E: node.clientWidth,
				z: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			aH: _Browser_getScene(),
			aP: {
				T: x,
				U: y,
				E: _Browser_doc.documentElement.clientWidth,
				z: _Browser_doc.documentElement.clientHeight
			},
			aV: {
				T: x + rect.left,
				U: y + rect.top,
				E: rect.width,
				z: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}
var elm$core$Array$branchFactor = 32;
var elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var elm$core$Basics$EQ = 1;
var elm$core$Basics$GT = 2;
var elm$core$Basics$LT = 0;
var elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3(elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var elm$core$List$cons = _List_cons;
var elm$core$Dict$toList = function (dict) {
	return A3(
		elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var elm$core$Dict$keys = function (dict) {
	return A3(
		elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2(elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var elm$core$Set$toList = function (_n0) {
	var dict = _n0;
	return elm$core$Dict$keys(dict);
};
var elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var elm$core$Array$foldr = F3(
	function (func, baseCase, _n0) {
		var tree = _n0.c;
		var tail = _n0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3(elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3(elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			elm$core$Elm$JsArray$foldr,
			helper,
			A3(elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var elm$core$Array$toList = function (array) {
	return A3(elm$core$Array$foldr, elm$core$List$cons, _List_Nil, array);
};
var elm$core$Basics$ceiling = _Basics_ceiling;
var elm$core$Basics$fdiv = _Basics_fdiv;
var elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var elm$core$Basics$toFloat = _Basics_toFloat;
var elm$core$Array$shiftStep = elm$core$Basics$ceiling(
	A2(elm$core$Basics$logBase, 2, elm$core$Array$branchFactor));
var elm$core$Elm$JsArray$empty = _JsArray_empty;
var elm$core$Array$empty = A4(elm$core$Array$Array_elm_builtin, 0, elm$core$Array$shiftStep, elm$core$Elm$JsArray$empty, elm$core$Elm$JsArray$empty);
var elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var elm$core$List$reverse = function (list) {
	return A3(elm$core$List$foldl, elm$core$List$cons, _List_Nil, list);
};
var elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _n0 = A2(elm$core$Elm$JsArray$initializeFromList, elm$core$Array$branchFactor, nodes);
			var node = _n0.a;
			var remainingNodes = _n0.b;
			var newAcc = A2(
				elm$core$List$cons,
				elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var elm$core$Basics$eq = _Utils_equal;
var elm$core$Tuple$first = function (_n0) {
	var x = _n0.a;
	return x;
};
var elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = elm$core$Basics$ceiling(nodeListSize / elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2(elm$core$Elm$JsArray$initializeFromList, elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2(elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var elm$core$Basics$add = _Basics_add;
var elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var elm$core$Basics$floor = _Basics_floor;
var elm$core$Basics$gt = _Utils_gt;
var elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var elm$core$Basics$mul = _Basics_mul;
var elm$core$Basics$sub = _Basics_sub;
var elm$core$Elm$JsArray$length = _JsArray_length;
var elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.a) {
			return A4(
				elm$core$Array$Array_elm_builtin,
				elm$core$Elm$JsArray$length(builder.c),
				elm$core$Array$shiftStep,
				elm$core$Elm$JsArray$empty,
				builder.c);
		} else {
			var treeLen = builder.a * elm$core$Array$branchFactor;
			var depth = elm$core$Basics$floor(
				A2(elm$core$Basics$logBase, elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? elm$core$List$reverse(builder.d) : builder.d;
			var tree = A2(elm$core$Array$treeFromBuilder, correctNodeList, builder.a);
			return A4(
				elm$core$Array$Array_elm_builtin,
				elm$core$Elm$JsArray$length(builder.c) + treeLen,
				A2(elm$core$Basics$max, 5, depth * elm$core$Array$shiftStep),
				tree,
				builder.c);
		}
	});
var elm$core$Basics$False = 1;
var elm$core$Basics$idiv = _Basics_idiv;
var elm$core$Basics$lt = _Utils_lt;
var elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					elm$core$Array$builderToArray,
					false,
					{d: nodeList, a: (len / elm$core$Array$branchFactor) | 0, c: tail});
			} else {
				var leaf = elm$core$Array$Leaf(
					A3(elm$core$Elm$JsArray$initialize, elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2(elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var elm$core$Basics$le = _Utils_le;
var elm$core$Basics$remainderBy = _Basics_remainderBy;
var elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return elm$core$Array$empty;
		} else {
			var tailLen = len % elm$core$Array$branchFactor;
			var tail = A3(elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - elm$core$Array$branchFactor;
			return A5(elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var elm$core$Maybe$Nothing = {$: 1};
var elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var elm$core$Basics$True = 0;
var elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var elm$core$Basics$and = _Basics_and;
var elm$core$Basics$append = _Utils_append;
var elm$core$Basics$or = _Basics_or;
var elm$core$Char$toCode = _Char_toCode;
var elm$core$Char$isLower = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var elm$core$Char$isUpper = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var elm$core$Char$isAlpha = function (_char) {
	return elm$core$Char$isLower(_char) || elm$core$Char$isUpper(_char);
};
var elm$core$Char$isDigit = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var elm$core$Char$isAlphaNum = function (_char) {
	return elm$core$Char$isLower(_char) || (elm$core$Char$isUpper(_char) || elm$core$Char$isDigit(_char));
};
var elm$core$List$length = function (xs) {
	return A3(
		elm$core$List$foldl,
		F2(
			function (_n0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var elm$core$List$map2 = _List_map2;
var elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2(elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var elm$core$List$range = F2(
	function (lo, hi) {
		return A3(elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			elm$core$List$map2,
			f,
			A2(
				elm$core$List$range,
				0,
				elm$core$List$length(xs) - 1),
			xs);
	});
var elm$core$String$all = _String_all;
var elm$core$String$fromInt = _String_fromNumber;
var elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var elm$core$String$uncons = _String_uncons;
var elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var elm$json$Json$Decode$indent = function (str) {
	return A2(
		elm$core$String$join,
		'\n    ',
		A2(elm$core$String$split, '\n', str));
};
var elm$json$Json$Encode$encode = _Json_encode;
var elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + (elm$core$String$fromInt(i + 1) + (') ' + elm$json$Json$Decode$indent(
			elm$json$Json$Decode$errorToString(error))));
	});
var elm$json$Json$Decode$errorToString = function (error) {
	return A2(elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _n1 = elm$core$String$uncons(f);
						if (_n1.$ === 1) {
							return false;
						} else {
							var _n2 = _n1.a;
							var _char = _n2.a;
							var rest = _n2.b;
							return elm$core$Char$isAlpha(_char) && A2(elm$core$String$all, elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2(elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + (elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2(elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									elm$core$String$join,
									'',
									elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										elm$core$String$join,
										'',
										elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + (elm$core$String$fromInt(
								elm$core$List$length(errors)) + ' ways:'));
							return A2(
								elm$core$String$join,
								'\n\n',
								A2(
									elm$core$List$cons,
									introduction,
									A2(elm$core$List$indexedMap, elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								elm$core$String$join,
								'',
								elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + (elm$json$Json$Decode$indent(
						A2(elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var elm$json$Json$Encode$string = _Json_wrap;
var author$project$AudioPorts$requestLoadSoundFont = _Platform_outgoingPort('requestLoadSoundFont', elm$json$Json$Encode$string);
var author$project$Game$Learn = 0;
var author$project$GuessChordGame$Incomplete = 1;
var author$project$GuessChordGame$initialGuess = {aq: elm$core$Maybe$Nothing, aB: elm$core$Maybe$Nothing, aF: elm$core$Maybe$Nothing, aL: elm$core$Maybe$Nothing};
var author$project$GuessChordGame$init = {ad: elm$core$Maybe$Nothing, al: author$project$GuessChordGame$initialGuess, ao: 1};
var author$project$GuessNotesGame$NotSelected = 0;
var author$project$GuessNotesGame$NotStarted = 1;
var author$project$GuessNotesGame$init = {V: 1, ao: 0, a_: _List_Nil};
var author$project$Guitar$guitarStrings = _List_fromArray(
	['E', 'B', 'G', 'D', 'A', 'E']);
var elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _n0 = A2(elm$core$Elm$JsArray$initializeFromList, elm$core$Array$branchFactor, list);
			var jsArray = _n0.a;
			var remainingItems = _n0.b;
			if (_Utils_cmp(
				elm$core$Elm$JsArray$length(jsArray),
				elm$core$Array$branchFactor) < 0) {
				return A2(
					elm$core$Array$builderToArray,
					true,
					{d: nodeList, a: nodeListSize, c: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					elm$core$List$cons,
					elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return elm$core$Array$empty;
	} else {
		return A3(elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var elm$core$Array$bitMask = 4294967295 >>> (32 - elm$core$Array$shiftStep);
var elm$core$Bitwise$and = _Bitwise_and;
var elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = elm$core$Array$bitMask & (index >>> shift);
			var _n0 = A2(elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_n0.$) {
				var subTree = _n0.a;
				var $temp$shift = shift - elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _n0.a;
				return A2(elm$core$Elm$JsArray$unsafeGet, elm$core$Array$bitMask & index, values);
			}
		}
	});
var elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var elm$core$Basics$ge = _Utils_ge;
var elm$core$Array$get = F2(
	function (index, _n0) {
		var len = _n0.a;
		var startShift = _n0.b;
		var tree = _n0.c;
		var tail = _n0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			elm$core$Array$tailIndex(len)) > -1) ? elm$core$Maybe$Just(
			A2(elm$core$Elm$JsArray$unsafeGet, elm$core$Array$bitMask & index, tail)) : elm$core$Maybe$Just(
			A3(elm$core$Array$getHelp, startShift, index, tree)));
	});
var elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var author$project$Guitar$getGuitarStringName = function (num) {
	var guitarString = A2(
		elm$core$Array$get,
		num - 1,
		elm$core$Array$fromList(author$project$Guitar$guitarStrings));
	return A2(elm$core$Maybe$withDefault, '', guitarString);
};
var author$project$Music$notes = _List_fromArray(
	['A', 'A#/Bb', 'B', 'C', 'C#/Db', 'D', 'D#/Eb', 'E', 'F', 'F#/Gb', 'G', 'G#/Ab']);
var author$project$Music$getNoteNameByIndex = function (index) {
	var noteName = A2(
		elm$core$Array$get,
		index,
		elm$core$Array$fromList(author$project$Music$notes));
	return A2(elm$core$Maybe$withDefault, 'Err!', noteName);
};
var elm_community$list_extra$List$Extra$findIndexHelp = F3(
	function (index, predicate, list) {
		findIndexHelp:
		while (true) {
			if (!list.b) {
				return elm$core$Maybe$Nothing;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					return elm$core$Maybe$Just(index);
				} else {
					var $temp$index = index + 1,
						$temp$predicate = predicate,
						$temp$list = xs;
					index = $temp$index;
					predicate = $temp$predicate;
					list = $temp$list;
					continue findIndexHelp;
				}
			}
		}
	});
var elm_community$list_extra$List$Extra$findIndex = elm_community$list_extra$List$Extra$findIndexHelp(0);
var elm_community$list_extra$List$Extra$elemIndex = function (x) {
	return elm_community$list_extra$List$Extra$findIndex(
		elm$core$Basics$eq(x));
};
var author$project$Guitar$getGuitarNoteName = F2(
	function (stringNum, fretNum) {
		var stringName = author$project$Guitar$getGuitarStringName(stringNum);
		var stringNoteIndex = A2(
			elm$core$Maybe$withDefault,
			0,
			A2(elm_community$list_extra$List$Extra$elemIndex, stringName, author$project$Music$notes));
		var virtualIndex = stringNoteIndex + fretNum;
		var noteCount = elm$core$List$length(author$project$Music$notes);
		var wholeCycles = elm$core$Basics$floor(virtualIndex / noteCount);
		var selectedNoteIndex = (_Utils_cmp(virtualIndex, noteCount) > -1) ? (virtualIndex - (wholeCycles * noteCount)) : virtualIndex;
		return author$project$Music$getNoteNameByIndex(selectedNoteIndex);
	});
var author$project$Guitar$guitarStringPitches = _List_fromArray(
	[4, 3, 3, 3, 2, 2]);
var elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var author$project$Guitar$guitarStringsWithPitches = A3(elm$core$List$map2, elm$core$Tuple$pair, author$project$Guitar$guitarStrings, author$project$Guitar$guitarStringPitches);
var elm$core$Basics$negate = function (n) {
	return -n;
};
var elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							elm$core$List$foldl,
							fn,
							acc,
							elm$core$List$reverse(r4)) : A4(elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4(elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2(elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var elm$core$Tuple$second = function (_n0) {
	var y = _n0.b;
	return y;
};
var author$project$Guitar$getGuitarNoteWithPitch = F2(
	function (stringNum, fretNum) {
		var stringPitch = A2(
			elm$core$Maybe$withDefault,
			_Utils_Tuple2('E', -1000),
			A2(
				elm$core$Array$get,
				stringNum - 1,
				elm$core$Array$fromList(author$project$Guitar$guitarStringsWithPitches)));
		var octaveCountInRange = elm$core$List$length(
			A2(
				elm$core$List$filter,
				function (fret) {
					return A2(author$project$Guitar$getGuitarNoteName, stringNum, fret) === 'C';
				},
				A2(elm$core$List$range, 0, fretNum)));
		var pitch = stringPitch.b + octaveCountInRange;
		var noteName = A2(author$project$Guitar$getGuitarNoteName, stringNum, fretNum);
		return _Utils_Tuple2(noteName, pitch);
	});
var author$project$Guitar$createGuitarNote = F2(
	function (stringNum, fretNum) {
		return {
			aj: fretNum,
			a4: A2(author$project$Guitar$getGuitarNoteName, stringNum, fretNum),
			Y: A2(author$project$Guitar$getGuitarNoteWithPitch, stringNum, fretNum),
			aJ: author$project$Guitar$getGuitarStringName(stringNum),
			aK: stringNum
		};
	});
var author$project$Chord$MajorSeventh = 5;
var author$project$GuitarChord$FirstSetBrokenFour = 3;
var author$project$ShowChord$init = {aq: 0, aB: 5, aF: 'D', aL: 3};
var author$project$Model$init = _Utils_Tuple2(
	{
		u: 0,
		am: author$project$GuessChordGame$init,
		an: author$project$GuessNotesGame$init,
		aI: A2(author$project$Guitar$createGuitarNote, 6, 0),
		ba: _List_Nil,
		S: author$project$ShowChord$init,
		bb: false
	},
	author$project$AudioPorts$requestLoadSoundFont('/soundfonts'));
var author$project$GuessChordGame$Correct = 0;
var author$project$GuessChordGame$Incorrect = 2;
var author$project$GuessChordGame$guessStateByGuessAnswer = F2(
	function (guess, answer) {
		var _n0 = _Utils_Tuple3(
			_Utils_Tuple2(guess.aF, guess.aL),
			_Utils_Tuple2(guess.aq, guess.aB),
			answer);
		if (_n0.c.$ === 1) {
			var _n1 = _n0.c;
			return 1;
		} else {
			if (_n0.a.a.$ === 1) {
				var _n2 = _n0.a;
				var _n3 = _n2.a;
				var _n4 = _n0.b;
				return 1;
			} else {
				if (_n0.a.b.$ === 1) {
					var _n5 = _n0.a;
					var _n6 = _n5.b;
					var _n7 = _n0.b;
					return 1;
				} else {
					if (_n0.b.a.$ === 1) {
						var _n8 = _n0.a;
						var _n9 = _n0.b;
						var _n10 = _n9.a;
						return 1;
					} else {
						if (_n0.b.b.$ === 1) {
							var _n11 = _n0.a;
							var _n12 = _n0.b;
							var _n13 = _n12.b;
							return 1;
						} else {
							var _n14 = _n0.a;
							var _n15 = _n0.b;
							var ans = _n0.c.a;
							var stringSetsMatch = _Utils_eq(
								guess.aL,
								elm$core$Maybe$Just(ans.aL));
							var rootsMatch = _Utils_eq(
								guess.aF,
								elm$core$Maybe$Just(ans.aF));
							var qualitiesMatch = _Utils_eq(
								guess.aB,
								elm$core$Maybe$Just(ans.aB));
							var inversionsMatch = _Utils_eq(
								guess.aq,
								elm$core$Maybe$Just(ans.aq));
							var isCorrect = rootsMatch && (inversionsMatch && (qualitiesMatch && stringSetsMatch));
							return isCorrect ? 0 : 2;
						}
					}
				}
			}
		}
	});
var author$project$GuessNotesGame$Correct = 1;
var author$project$GuessNotesGame$Incorrect = 2;
var author$project$GuessNotesGame$appendGuess = F2(
	function (note, guessNotesGame) {
		return _Utils_update(
			guessNotesGame,
			{
				a_: _Utils_ap(
					guessNotesGame.a_,
					_List_fromArray(
						[note]))
			});
	});
var author$project$GuessNotesGame$setGuessState = F2(
	function (guessState, guessNotesGame) {
		return _Utils_update(
			guessNotesGame,
			{ao: guessState});
	});
var author$project$GuessNotesGame$setGuesses = F2(
	function (guesses, guessNotesGame) {
		return _Utils_update(
			guessNotesGame,
			{a_: guesses});
	});
var elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var author$project$Guitar$getAllStringNotes = F2(
	function (stringNum, numberOfFrets) {
		return A2(
			elm$core$List$map,
			author$project$Guitar$createGuitarNote(stringNum),
			A2(elm$core$List$range, 0, numberOfFrets));
	});
var elm$core$Array$toIndexedList = function (array) {
	var len = array.a;
	var helper = F2(
		function (entry, _n0) {
			var index = _n0.a;
			var list = _n0.b;
			return _Utils_Tuple2(
				index - 1,
				A2(
					elm$core$List$cons,
					_Utils_Tuple2(index, entry),
					list));
		});
	return A3(
		elm$core$Array$foldr,
		helper,
		_Utils_Tuple2(len - 1, _List_Nil),
		array).b;
};
var elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3(elm$core$List$foldr, elm$core$List$cons, ys, xs);
		}
	});
var elm$core$List$concat = function (lists) {
	return A3(elm$core$List$foldr, elm$core$List$append, _List_Nil, lists);
};
var elm$core$List$concatMap = F2(
	function (f, list) {
		return elm$core$List$concat(
			A2(elm$core$List$map, f, list));
	});
var author$project$Guitar$findAllOctaves = F2(
	function (note, numberOfFrets) {
		var findMatchingNotes = function (_n0) {
			var stringIndex = _n0.a;
			return A2(
				elm$core$List$filter,
				function (n) {
					return _Utils_eq(n.a4, note);
				},
				A2(author$project$Guitar$getAllStringNotes, stringIndex + 1, numberOfFrets));
		};
		return A2(
			elm$core$List$concatMap,
			findMatchingNotes,
			elm$core$Array$toIndexedList(
				elm$core$Array$fromList(author$project$Guitar$guitarStrings)));
	});
var author$project$AudioPorts$playNote = _Platform_outgoingPort('playNote', elm$json$Json$Encode$string);
var elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return elm$core$Maybe$Just(x);
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var author$project$Music$pitchNotationToStr = function (spn) {
	var pitchString = elm$core$String$fromInt(spn.b);
	var normalizedNoteName = A2(
		elm$core$Maybe$withDefault,
		'ERROR!',
		elm$core$List$head(
			elm$core$List$reverse(
				A2(elm$core$String$split, '/', spn.a))));
	return A2(
		elm$core$String$join,
		'',
		_List_fromArray(
			[normalizedNoteName, pitchString]));
};
var author$project$Guitar$playNoteAudio = function (guitarNote) {
	return author$project$AudioPorts$playNote(
		author$project$Music$pitchNotationToStr(guitarNote.Y));
};
var author$project$Model$asGuessNotesGameIn = F2(
	function (model, gameState) {
		return _Utils_update(
			model,
			{an: gameState});
	});
var author$project$Msg$RandomGuitarChordSelected = function (a) {
	return {$: 6, a: a};
};
var author$project$GuitarChord$GuitarChord = F4(
	function (rootNote, quality, inversion, stringSet) {
		return {aq: inversion, aB: quality, aF: rootNote, aL: stringSet};
	});
var elm$core$Basics$identity = function (x) {
	return x;
};
var elm$random$Random$Generator = elm$core$Basics$identity;
var elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$random$Random$next = function (_n0) {
	var state0 = _n0.a;
	var incr = _n0.b;
	return A2(elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var elm$core$Bitwise$xor = _Bitwise_xor;
var elm$random$Random$peel = function (_n0) {
	var state = _n0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var elm$random$Random$int = F2(
	function (a, b) {
		return function (seed0) {
			var _n0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
			var lo = _n0.a;
			var hi = _n0.b;
			var range = (hi - lo) + 1;
			if (!((range - 1) & range)) {
				return _Utils_Tuple2(
					(((range - 1) & elm$random$Random$peel(seed0)) >>> 0) + lo,
					elm$random$Random$next(seed0));
			} else {
				var threshhold = (((-range) >>> 0) % range) >>> 0;
				var accountForBias = function (seed) {
					accountForBias:
					while (true) {
						var x = elm$random$Random$peel(seed);
						var seedN = elm$random$Random$next(seed);
						if (_Utils_cmp(x, threshhold) < 0) {
							var $temp$seed = seedN;
							seed = $temp$seed;
							continue accountForBias;
						} else {
							return _Utils_Tuple2((x % range) + lo, seedN);
						}
					}
				};
				return accountForBias(seed0);
			}
		};
	});
var author$project$Update$randomInversion = A2(elm$random$Random$int, 0, 3);
var author$project$Chord$DiminishedSeventh = 11;
var author$project$Chord$HalfDiminishedSeventh = 10;
var author$project$Chord$MinorSeventh = 7;
var author$project$Chord$Seventh = 4;
var author$project$GuessChordGame$guessChordQualities = _List_fromArray(
	[
		_Utils_Tuple2(5, 'Δ'),
		_Utils_Tuple2(4, '7'),
		_Utils_Tuple2(7, '-7'),
		_Utils_Tuple2(10, 'Ø7'),
		_Utils_Tuple2(11, 'O7')
	]);
var elm$random$Random$addOne = function (value) {
	return _Utils_Tuple2(1, value);
};
var elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var elm$core$List$sum = function (numbers) {
	return A3(elm$core$List$foldl, elm$core$Basics$add, 0, numbers);
};
var elm$random$Random$float = F2(
	function (a, b) {
		return function (seed0) {
			var seed1 = elm$random$Random$next(seed0);
			var range = elm$core$Basics$abs(b - a);
			var n1 = elm$random$Random$peel(seed1);
			var n0 = elm$random$Random$peel(seed0);
			var lo = (134217727 & n1) * 1.0;
			var hi = (67108863 & n0) * 1.0;
			var val = ((hi * 1.34217728e8) + lo) / 9.007199254740992e15;
			var scaled = (val * range) + a;
			return _Utils_Tuple2(
				scaled,
				elm$random$Random$next(seed1));
		};
	});
var elm$random$Random$getByWeight = F3(
	function (_n0, others, countdown) {
		getByWeight:
		while (true) {
			var weight = _n0.a;
			var value = _n0.b;
			if (!others.b) {
				return value;
			} else {
				var second = others.a;
				var otherOthers = others.b;
				if (_Utils_cmp(
					countdown,
					elm$core$Basics$abs(weight)) < 1) {
					return value;
				} else {
					var $temp$_n0 = second,
						$temp$others = otherOthers,
						$temp$countdown = countdown - elm$core$Basics$abs(weight);
					_n0 = $temp$_n0;
					others = $temp$others;
					countdown = $temp$countdown;
					continue getByWeight;
				}
			}
		}
	});
var elm$random$Random$map = F2(
	function (func, _n0) {
		var genA = _n0;
		return function (seed0) {
			var _n1 = genA(seed0);
			var a = _n1.a;
			var seed1 = _n1.b;
			return _Utils_Tuple2(
				func(a),
				seed1);
		};
	});
var elm$random$Random$weighted = F2(
	function (first, others) {
		var normalize = function (_n0) {
			var weight = _n0.a;
			return elm$core$Basics$abs(weight);
		};
		var total = normalize(first) + elm$core$List$sum(
			A2(elm$core$List$map, normalize, others));
		return A2(
			elm$random$Random$map,
			A2(elm$random$Random$getByWeight, first, others),
			A2(elm$random$Random$float, 0, total));
	});
var elm$random$Random$uniform = F2(
	function (value, valueList) {
		return A2(
			elm$random$Random$weighted,
			elm$random$Random$addOne(value),
			A2(elm$core$List$map, elm$random$Random$addOne, valueList));
	});
var author$project$Update$randomQuality = function () {
	var chordQualities = A2(
		elm$core$List$map,
		function (_n1) {
			var q = _n1.a;
			return q;
		},
		author$project$GuessChordGame$guessChordQualities);
	if (chordQualities.b) {
		var first = chordQualities.a;
		var rest = chordQualities.b;
		return A2(elm$random$Random$uniform, first, rest);
	} else {
		return A2(elm$random$Random$uniform, 5, _List_Nil);
	}
}();
var author$project$Update$randomRootNote = function () {
	var _n0 = author$project$Music$notes;
	if (_n0.b) {
		var first = _n0.a;
		var rest = _n0.b;
		return A2(elm$random$Random$uniform, first, rest);
	} else {
		return A2(elm$random$Random$uniform, 'C', _List_Nil);
	}
}();
var author$project$GuitarChord$FirstSetFour = 0;
var author$project$GuitarChord$SecondSetBrokenFour = 4;
var author$project$GuitarChord$SecondSetFour = 1;
var author$project$GuitarChord$ThirdSetFour = 2;
var author$project$GuitarChord$stringSets = _List_fromArray(
	[0, 1, 2, 3, 4]);
var author$project$Update$randomStringSet = function () {
	var _n0 = author$project$GuitarChord$stringSets;
	if (_n0.b) {
		var first = _n0.a;
		var rest = _n0.b;
		return A2(elm$random$Random$uniform, first, rest);
	} else {
		return A2(elm$random$Random$uniform, 0, _List_Nil);
	}
}();
var elm$random$Random$map4 = F5(
	function (func, _n0, _n1, _n2, _n3) {
		var genA = _n0;
		var genB = _n1;
		var genC = _n2;
		var genD = _n3;
		return function (seed0) {
			var _n4 = genA(seed0);
			var a = _n4.a;
			var seed1 = _n4.b;
			var _n5 = genB(seed1);
			var b = _n5.a;
			var seed2 = _n5.b;
			var _n6 = genC(seed2);
			var c = _n6.a;
			var seed3 = _n6.b;
			var _n7 = genD(seed3);
			var d = _n7.a;
			var seed4 = _n7.b;
			return _Utils_Tuple2(
				A4(func, a, b, c, d),
				seed4);
		};
	});
var author$project$Update$pickRandomChord = A5(elm$random$Random$map4, author$project$GuitarChord$GuitarChord, author$project$Update$randomRootNote, author$project$Update$randomQuality, author$project$Update$randomInversion, author$project$Update$randomStringSet);
var elm$random$Random$Generate = elm$core$Basics$identity;
var elm$core$Task$andThen = _Scheduler_andThen;
var elm$core$Task$succeed = _Scheduler_succeed;
var elm$random$Random$initialSeed = function (x) {
	var _n0 = elm$random$Random$next(
		A2(elm$random$Random$Seed, 0, 1013904223));
	var state1 = _n0.a;
	var incr = _n0.b;
	var state2 = (state1 + x) >>> 0;
	return elm$random$Random$next(
		A2(elm$random$Random$Seed, state2, incr));
};
var elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$time$Time$customZone = elm$time$Time$Zone;
var elm$time$Time$Posix = elm$core$Basics$identity;
var elm$time$Time$millisToPosix = elm$core$Basics$identity;
var elm$time$Time$now = _Time_now(elm$time$Time$millisToPosix);
var elm$time$Time$posixToMillis = function (_n0) {
	var millis = _n0;
	return millis;
};
var elm$random$Random$init = A2(
	elm$core$Task$andThen,
	function (time) {
		return elm$core$Task$succeed(
			elm$random$Random$initialSeed(
				elm$time$Time$posixToMillis(time)));
	},
	elm$time$Time$now);
var elm$core$Platform$sendToApp = _Platform_sendToApp;
var elm$random$Random$step = F2(
	function (_n0, seed) {
		var generator = _n0;
		return generator(seed);
	});
var elm$random$Random$onEffects = F3(
	function (router, commands, seed) {
		if (!commands.b) {
			return elm$core$Task$succeed(seed);
		} else {
			var generator = commands.a;
			var rest = commands.b;
			var _n1 = A2(elm$random$Random$step, generator, seed);
			var value = _n1.a;
			var newSeed = _n1.b;
			return A2(
				elm$core$Task$andThen,
				function (_n2) {
					return A3(elm$random$Random$onEffects, router, rest, newSeed);
				},
				A2(elm$core$Platform$sendToApp, router, value));
		}
	});
var elm$random$Random$onSelfMsg = F3(
	function (_n0, _n1, seed) {
		return elm$core$Task$succeed(seed);
	});
var elm$random$Random$cmdMap = F2(
	function (func, _n0) {
		var generator = _n0;
		return A2(elm$random$Random$map, func, generator);
	});
_Platform_effectManagers['Random'] = _Platform_createManager(elm$random$Random$init, elm$random$Random$onEffects, elm$random$Random$onSelfMsg, elm$random$Random$cmdMap);
var elm$random$Random$command = _Platform_leaf('Random');
var elm$random$Random$generate = F2(
	function (tagger, generator) {
		return elm$random$Random$command(
			A2(elm$random$Random$map, tagger, generator));
	});
var author$project$Update$generateRandomGuitarChord = A2(elm$random$Random$generate, author$project$Msg$RandomGuitarChordSelected, author$project$Update$pickRandomChord);
var author$project$Msg$RandomGuitarNoteSelected = function (a) {
	return {$: 5, a: a};
};
var author$project$Update$randomFret = A2(elm$random$Random$int, 0, 12);
var author$project$Update$randomString = A2(elm$random$Random$int, 1, 6);
var elm$random$Random$map2 = F3(
	function (func, _n0, _n1) {
		var genA = _n0;
		var genB = _n1;
		return function (seed0) {
			var _n2 = genA(seed0);
			var a = _n2.a;
			var seed1 = _n2.b;
			var _n3 = genB(seed1);
			var b = _n3.a;
			var seed2 = _n3.b;
			return _Utils_Tuple2(
				A2(func, a, b),
				seed2);
		};
	});
var author$project$Update$pickRandomNote = A3(elm$random$Random$map2, author$project$Guitar$createGuitarNote, author$project$Update$randomString, author$project$Update$randomFret);
var author$project$Update$generateRandomGuitarNote = A2(elm$random$Random$generate, author$project$Msg$RandomGuitarNoteSelected, author$project$Update$pickRandomNote);
var author$project$Update$toTupleWithCmd = F2(
	function (cmd, model) {
		return _Utils_Tuple2(model, cmd);
	});
var elm$core$Platform$Cmd$batch = _Platform_batch;
var elm$core$Platform$Cmd$none = elm$core$Platform$Cmd$batch(_List_Nil);
var author$project$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 13:
				var stringSet = msg.a;
				var guessChordGame = model.am;
				var guess = guessChordGame.al;
				var newGuess = _Utils_update(
					guess,
					{
						aL: elm$core$Maybe$Just(stringSet)
					});
				var newGuessState = A2(author$project$GuessChordGame$guessStateByGuessAnswer, newGuess, guessChordGame.ad);
				var newGuessChordGame = _Utils_update(
					guessChordGame,
					{al: newGuess, ao: newGuessState});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{am: newGuessChordGame}),
					elm$core$Platform$Cmd$none);
			case 14:
				var quality = msg.a;
				var guessChordGame = model.am;
				var guess = guessChordGame.al;
				var newGuess = _Utils_update(
					guess,
					{
						aB: elm$core$Maybe$Just(quality)
					});
				var newGuessState = A2(author$project$GuessChordGame$guessStateByGuessAnswer, newGuess, guessChordGame.ad);
				var newGuessChordGame = _Utils_update(
					guessChordGame,
					{al: newGuess, ao: newGuessState});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{am: newGuessChordGame}),
					elm$core$Platform$Cmd$none);
			case 16:
				var inversion = msg.a;
				var guessChordGame = model.am;
				var guess = guessChordGame.al;
				var newGuess = _Utils_update(
					guess,
					{
						aq: elm$core$Maybe$Just(inversion)
					});
				var newGuessState = A2(author$project$GuessChordGame$guessStateByGuessAnswer, newGuess, guessChordGame.ad);
				var newGuessChordGame = _Utils_update(
					guessChordGame,
					{al: newGuess, ao: newGuessState});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{am: newGuessChordGame}),
					elm$core$Platform$Cmd$none);
			case 15:
				var note = msg.a;
				var guessChordGame = model.am;
				var guess = guessChordGame.al;
				var newGuess = _Utils_update(
					guess,
					{
						aF: elm$core$Maybe$Just(note)
					});
				var newGuessState = A2(author$project$GuessChordGame$guessStateByGuessAnswer, newGuess, guessChordGame.ad);
				var newGuessChordGame = _Utils_update(
					guessChordGame,
					{al: newGuess, ao: newGuessState});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{am: newGuessChordGame}),
					elm$core$Platform$Cmd$none);
			case 8:
				var stringSet = msg.a;
				var showChord = model.S;
				var newShowChord = _Utils_update(
					showChord,
					{aL: stringSet});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{S: newShowChord}),
					elm$core$Platform$Cmd$none);
			case 9:
				var quality = msg.a;
				var showChord = model.S;
				var newShowChord = _Utils_update(
					showChord,
					{aB: quality});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{S: newShowChord}),
					elm$core$Platform$Cmd$none);
			case 11:
				var inversion = msg.a;
				var showChord = model.S;
				var newShowChord = _Utils_update(
					showChord,
					{aq: inversion});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{S: newShowChord}),
					elm$core$Platform$Cmd$none);
			case 10:
				var note = msg.a;
				var showChord = model.S;
				var newShowChord = _Utils_update(
					showChord,
					{aF: note});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{S: newShowChord}),
					elm$core$Platform$Cmd$none);
			case 12:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{am: author$project$GuessChordGame$init}),
					author$project$Update$generateRandomGuitarChord);
			case 0:
				var mode = msg.a;
				var currentNote = model.aI.a4;
				var octaves = model.bb ? A2(author$project$Guitar$findAllOctaves, currentNote, 12) : _List_Nil;
				var cmd = function () {
					switch (mode) {
						case 1:
							return author$project$Update$generateRandomGuitarNote;
						case 2:
							return author$project$Update$generateRandomGuitarChord;
						default:
							return elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{u: mode, an: author$project$GuessNotesGame$init, ba: octaves}),
					cmd);
			case 1:
				var note = msg.a;
				var _n2 = _Utils_eq(model.aI.a4, note);
				if (_n2) {
					return A2(
						author$project$Update$toTupleWithCmd,
						author$project$Update$generateRandomGuitarNote,
						A2(
							author$project$Model$asGuessNotesGameIn,
							model,
							A2(
								author$project$GuessNotesGame$setGuessState,
								1,
								A2(author$project$GuessNotesGame$setGuesses, _List_Nil, model.an))));
				} else {
					return A2(
						author$project$Update$toTupleWithCmd,
						elm$core$Platform$Cmd$none,
						A2(
							author$project$Model$asGuessNotesGameIn,
							model,
							A2(
								author$project$GuessNotesGame$setGuessState,
								2,
								A2(author$project$GuessNotesGame$appendGuess, note, model.an))));
				}
			case 2:
				var stringNum = msg.a;
				var fretNum = msg.b;
				var guitarNote = A2(author$project$Guitar$createGuitarNote, stringNum, fretNum);
				var octaves = model.bb ? A2(author$project$Guitar$findAllOctaves, guitarNote.a4, 12) : _List_Nil;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aI: guitarNote, ba: octaves}),
					author$project$Guitar$playNoteAudio(guitarNote));
			case 4:
				return _Utils_Tuple2(model, author$project$Update$generateRandomGuitarNote);
			case 6:
				var guitarChord = msg.a;
				var _n3 = model;
				var guessChordGame = _n3.am;
				var newGuessChordGame = _Utils_update(
					guessChordGame,
					{
						ad: elm$core$Maybe$Just(guitarChord)
					});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{am: newGuessChordGame}),
					elm$core$Platform$Cmd$none);
			case 5:
				var guitarNote = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aI: guitarNote, ba: _List_Nil}),
					author$project$Guitar$playNoteAudio(guitarNote));
			case 7:
				var value = msg.a;
				var octaves = value ? A2(author$project$Guitar$findAllOctaves, model.aI.a4, 12) : _List_Nil;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ba: octaves, bb: value}),
					elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
		}
	});
var author$project$Game$GuessChord = 2;
var author$project$Game$ShowChord = 3;
var author$project$Fretboard$isChordMode = function (model) {
	return (model.u === 2) || (model.u === 3);
};
var author$project$Fretboard$stringCount = elm$core$List$length(author$project$Guitar$guitarStrings);
var author$project$Interval$degreeToValue = function (d) {
	switch (d) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 5;
		case 6:
			return 6;
		case 7:
			return 7;
		case 8:
			return 8;
		case 9:
			return 9;
		case 10:
			return 10;
		case 11:
			return 11;
		case 12:
			return 12;
		default:
			return 13;
	}
};
var author$project$Tone$Natural = 0;
var author$project$Tone$A = 5;
var author$project$Tone$B = 6;
var author$project$Tone$C = 0;
var author$project$Tone$D = 1;
var author$project$Tone$E = 2;
var author$project$Tone$F = 3;
var author$project$Tone$G = 4;
var author$project$Tone$diatonicKeyFromValue = function (value) {
	switch (value) {
		case 0:
			return elm$core$Maybe$Just(0);
		case 1:
			return elm$core$Maybe$Just(1);
		case 2:
			return elm$core$Maybe$Just(2);
		case 3:
			return elm$core$Maybe$Just(3);
		case 4:
			return elm$core$Maybe$Just(4);
		case 5:
			return elm$core$Maybe$Just(5);
		case 6:
			return elm$core$Maybe$Just(6);
		default:
			return elm$core$Maybe$Nothing;
	}
};
var author$project$Tone$diatonicKeyValue = function (key) {
	switch (key) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 5;
		default:
			return 6;
	}
};
var author$project$Tone$newTone = F2(
	function (key, adjustment) {
		return {ac: adjustment, ar: key};
	});
var elm$core$Basics$modBy = _Basics_modBy;
var author$project$Interval$diatonicDegreeOf = F2(
	function (degree, note) {
		var keyValue = author$project$Tone$diatonicKeyValue(note.aO.ar);
		var degreeValue = author$project$Interval$degreeToValue(degree);
		var noteValue = A2(elm$core$Basics$modBy, 7, keyValue + degreeValue);
		var diatonicKey = author$project$Tone$diatonicKeyFromValue(noteValue);
		var octaveShift = ((keyValue + degreeValue) / 7) | 0;
		if (!diatonicKey.$) {
			var dk = diatonicKey.a;
			return {
				a5: note.a5 + octaveShift,
				aO: A2(author$project$Tone$newTone, dk, 0)
			};
		} else {
			return note;
		}
	});
var author$project$Tone$adjustmentToValue = function (adjustment) {
	switch (adjustment) {
		case 2:
			return -1;
		case 4:
			return -2;
		case 0:
			return 0;
		case 1:
			return 1;
		default:
			return 2;
	}
};
var author$project$Tone$keyToValue = function (key) {
	switch (key) {
		case 0:
			return 0;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 5;
		case 4:
			return 7;
		case 5:
			return 9;
		default:
			return 11;
	}
};
var author$project$Tone$toneToIndex = function (t) {
	return A2(
		elm$core$Basics$modBy,
		12,
		author$project$Tone$keyToValue(t.ar) + author$project$Tone$adjustmentToValue(t.ac));
};
var author$project$Note$noteToIndex = function (note) {
	return (note.a5 * 12) + author$project$Tone$toneToIndex(note.aO);
};
var author$project$Interval$distance = F2(
	function (from, to) {
		return author$project$Note$noteToIndex(to) - author$project$Note$noteToIndex(from);
	});
var author$project$Interval$Eleventh = 10;
var author$project$Interval$Fifth = 4;
var author$project$Interval$First = 0;
var author$project$Interval$Fourteenth = 13;
var author$project$Interval$Fourth = 3;
var author$project$Interval$Ninth = 8;
var author$project$Interval$Octave = 7;
var author$project$Interval$Second = 1;
var author$project$Interval$Seventh = 6;
var author$project$Interval$Sixth = 5;
var author$project$Interval$Tenth = 9;
var author$project$Interval$Third = 2;
var author$project$Interval$Thirteenth = 12;
var author$project$Interval$Twelfth = 11;
var author$project$Interval$intervalDegree = function (interval) {
	switch (interval) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 1;
		case 3:
			return 0;
		case 4:
			return 1;
		case 5:
			return 2;
		case 6:
			return 2;
		case 7:
			return 1;
		case 8:
			return 2;
		case 9:
			return 3;
		case 10:
			return 3;
		case 11:
			return 2;
		case 12:
			return 4;
		case 13:
			return 3;
		case 14:
			return 4;
		case 15:
			return 5;
		case 16:
			return 5;
		case 17:
			return 4;
		case 18:
			return 5;
		case 19:
			return 6;
		case 20:
			return 6;
		case 21:
			return 5;
		case 22:
			return 6;
		case 23:
			return 7;
		case 24:
			return 7;
		case 25:
			return 6;
		case 26:
			return 8;
		case 27:
			return 8;
		case 28:
			return 9;
		case 29:
			return 9;
		case 30:
			return 10;
		case 31:
			return 10;
		case 32:
			return 11;
		case 33:
			return 12;
		case 34:
			return 12;
		case 35:
			return 13;
		default:
			return 13;
	}
};
var author$project$Interval$intervalToValue = function (interval) {
	switch (interval) {
		case 0:
			return 0;
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 2;
		case 6:
			return 3;
		case 7:
			return 3;
		case 8:
			return 4;
		case 9:
			return 4;
		case 10:
			return 5;
		case 11:
			return 5;
		case 12:
			return 6;
		case 13:
			return 6;
		case 14:
			return 7;
		case 15:
			return 7;
		case 16:
			return 8;
		case 17:
			return 8;
		case 18:
			return 9;
		case 19:
			return 9;
		case 20:
			return 10;
		case 21:
			return 10;
		case 22:
			return 11;
		case 23:
			return 11;
		case 24:
			return 12;
		case 25:
			return 12;
		case 26:
			return 13;
		case 27:
			return 14;
		case 28:
			return 15;
		case 29:
			return 16;
		case 30:
			return 17;
		case 31:
			return 18;
		case 32:
			return 19;
		case 33:
			return 20;
		case 34:
			return 21;
		case 35:
			return 22;
		default:
			return 23;
	}
};
var author$project$Tone$Flat = 2;
var author$project$Tone$FlatFlat = 4;
var author$project$Tone$Sharp = 1;
var author$project$Tone$SharpSharp = 3;
var author$project$Tone$adjustmentFromValue = function (value) {
	var _n0 = _Utils_Tuple2(value, -value);
	_n0$0:
	while (true) {
		_n0$1:
		while (true) {
			switch (_n0.a) {
				case 0:
					switch (_n0.b) {
						case 2:
							break _n0$0;
						case 1:
							break _n0$1;
						default:
							return 0;
					}
				case 1:
					switch (_n0.b) {
						case 2:
							break _n0$0;
						case 1:
							break _n0$1;
						default:
							return 1;
					}
				case 2:
					switch (_n0.b) {
						case 2:
							break _n0$0;
						case 1:
							break _n0$1;
						default:
							return 3;
					}
				default:
					switch (_n0.b) {
						case 2:
							break _n0$0;
						case 1:
							break _n0$1;
						default:
							return 0;
					}
			}
		}
		return 2;
	}
	return 4;
};
var author$project$Interval$addInterval = F2(
	function (note, interval) {
		var newNaturalNote = A2(
			author$project$Interval$diatonicDegreeOf,
			author$project$Interval$intervalDegree(interval),
			note);
		var startToNewNaturalSemitones = A2(author$project$Interval$distance, note, newNaturalNote);
		var intervalSemitones = author$project$Interval$intervalToValue(interval);
		var adjustment = author$project$Tone$adjustmentFromValue(intervalSemitones - startToNewNaturalSemitones);
		var newOctave = ((author$project$Note$noteToIndex(newNaturalNote) + author$project$Tone$adjustmentToValue(adjustment)) / 12) | 0;
		return {
			a5: newOctave,
			aO: A2(author$project$Tone$newTone, newNaturalNote.aO.ar, adjustment)
		};
	});
var author$project$Music$toTone = function (note) {
	switch (note) {
		case 'A':
			return elm$core$Maybe$Just(
				{ac: 0, ar: 5});
		case 'A#':
			return elm$core$Maybe$Just(
				{ac: 1, ar: 5});
		case 'A#/Bb':
			return elm$core$Maybe$Just(
				{ac: 1, ar: 5});
		case 'Bb':
			return elm$core$Maybe$Just(
				{ac: 2, ar: 6});
		case 'B':
			return elm$core$Maybe$Just(
				{ac: 0, ar: 6});
		case 'C':
			return elm$core$Maybe$Just(
				{ac: 0, ar: 0});
		case 'C#':
			return elm$core$Maybe$Just(
				{ac: 1, ar: 0});
		case 'C#/Db':
			return elm$core$Maybe$Just(
				{ac: 1, ar: 0});
		case 'Db':
			return elm$core$Maybe$Just(
				{ac: 2, ar: 1});
		case 'D':
			return elm$core$Maybe$Just(
				{ac: 0, ar: 1});
		case 'D#':
			return elm$core$Maybe$Just(
				{ac: 1, ar: 1});
		case 'D#/Eb':
			return elm$core$Maybe$Just(
				{ac: 1, ar: 1});
		case 'Eb':
			return elm$core$Maybe$Just(
				{ac: 2, ar: 2});
		case 'E':
			return elm$core$Maybe$Just(
				{ac: 0, ar: 2});
		case 'F':
			return elm$core$Maybe$Just(
				{ac: 0, ar: 3});
		case 'F#':
			return elm$core$Maybe$Just(
				{ac: 1, ar: 3});
		case 'F#/Gb':
			return elm$core$Maybe$Just(
				{ac: 1, ar: 3});
		case 'Gb':
			return elm$core$Maybe$Just(
				{ac: 2, ar: 4});
		case 'G':
			return elm$core$Maybe$Just(
				{ac: 0, ar: 4});
		case 'G#':
			return elm$core$Maybe$Just(
				{ac: 1, ar: 4});
		case 'G#/Ab':
			return elm$core$Maybe$Just(
				{ac: 1, ar: 4});
		case 'Ab':
			return elm$core$Maybe$Just(
				{ac: 2, ar: 5});
		default:
			return elm$core$Maybe$Nothing;
	}
};
var elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? elm$core$Maybe$Nothing : elm$core$List$head(
			A2(elm$core$List$drop, idx, xs));
	});
var author$project$Fretboard$isChordTone = F5(
	function (rootTone, expectedIntervals, chordStringIntervals, stringNum, fretNum) {
		var stringInterval = function () {
			var _n5 = A2(elm_community$list_extra$List$Extra$getAt, author$project$Fretboard$stringCount - stringNum, chordStringIntervals);
			if (!_n5.$) {
				var a = _n5.a;
				return a;
			} else {
				return elm$core$Maybe$Nothing;
			}
		}();
		var rootNote = function () {
			var _n4 = _Utils_Tuple2(stringInterval, rootTone);
			if ((!_n4.a.$) && (!_n4.b.$)) {
				var tone = _n4.b.a;
				return elm$core$Maybe$Just(
					{a5: 1, aO: tone});
			} else {
				return elm$core$Maybe$Nothing;
			}
		}();
		var chordNote = function () {
			var _n3 = _Utils_Tuple2(rootNote, stringInterval);
			if ((!_n3.a.$) && (!_n3.b.$)) {
				var rn = _n3.a.a;
				var sivl = _n3.b.a;
				return elm$core$Maybe$Just(
					A2(author$project$Interval$addInterval, rn, sivl));
			} else {
				return elm$core$Maybe$Nothing;
			}
		}();
		var _n0 = A2(author$project$Guitar$getGuitarNoteWithPitch, stringNum, fretNum);
		var guitarNote = _n0.a;
		var stringTone = author$project$Music$toTone(guitarNote);
		var _n1 = _Utils_Tuple2(stringTone, chordNote);
		if ((!_n1.a.$) && (!_n1.b.$)) {
			var guitarTone = _n1.a.a;
			var note = _n1.b.a;
			var isInterval = function () {
				if (!stringInterval.$) {
					var interval = stringInterval.a;
					return A2(elm$core$List$member, interval, expectedIntervals);
				} else {
					return false;
				}
			}();
			var chordTone = note.aO;
			var retval = isInterval && _Utils_eq(
				author$project$Tone$toneToIndex(guitarTone),
				author$project$Tone$toneToIndex(chordTone));
			return retval;
		} else {
			return false;
		}
	});
var author$project$Fretboard$isOctave = F3(
	function (model, stringNum, fretNum) {
		return model.bb && A2(
			elm$core$List$any,
			function (note) {
				return _Utils_eq(note.aK, stringNum) && _Utils_eq(note.aj, fretNum);
			},
			model.ba);
	});
var author$project$Fretboard$isSelected = F3(
	function (model, stringNum, fretNum) {
		var selectedGuitarNote = model.aI;
		var selectedStringNum = selectedGuitarNote.aK;
		var selectedFretNum = selectedGuitarNote.aj;
		return _Utils_eq(selectedStringNum, stringNum) && _Utils_eq(selectedFretNum, fretNum);
	});
var author$project$Interval$AugmentedFifth = 17;
var author$project$Interval$MajorSeventh = 22;
var author$project$Interval$MajorThird = 8;
var author$project$Interval$PerfectUnison = 0;
var author$project$Chord$augmentedMajorSeventh = _List_fromArray(
	[0, 8, 17, 22]);
var author$project$Interval$MinorSeventh = 20;
var author$project$Chord$augmentedSeventh = _List_fromArray(
	[0, 8, 17, 20]);
var author$project$Chord$augmentedTriad = _List_fromArray(
	[0, 8, 17]);
var author$project$Interval$DiminishedFifth = 12;
var author$project$Interval$DiminishedSeventh = 19;
var author$project$Interval$MinorThird = 6;
var author$project$Chord$diminishedSeventh = _List_fromArray(
	[0, 6, 12, 19]);
var author$project$Chord$diminishedTriad = _List_fromArray(
	[0, 6, 12]);
var author$project$Chord$halfDiminishedSeventh = _List_fromArray(
	[0, 6, 12, 20]);
var author$project$Interval$PerfectFifth = 14;
var author$project$Chord$majorSeventh = _List_fromArray(
	[0, 8, 14, 22]);
var author$project$Chord$majorTriad = _List_fromArray(
	[0, 8, 14]);
var author$project$Chord$minorMajorSeventh = _List_fromArray(
	[0, 6, 14, 22]);
var author$project$Chord$minorSeventh = _List_fromArray(
	[0, 6, 14, 20]);
var author$project$Chord$minorTriad = _List_fromArray(
	[0, 6, 14]);
var author$project$Chord$seventh = _List_fromArray(
	[0, 8, 14, 20]);
var author$project$Chord$seventhFlatFive = _List_fromArray(
	[0, 8, 12, 20]);
var author$project$Chord$qualityToIntervals = function (quality) {
	switch (quality) {
		case 0:
			return author$project$Chord$majorTriad;
		case 1:
			return author$project$Chord$minorTriad;
		case 2:
			return author$project$Chord$augmentedTriad;
		case 3:
			return author$project$Chord$diminishedTriad;
		case 4:
			return author$project$Chord$seventh;
		case 5:
			return author$project$Chord$majorSeventh;
		case 6:
			return author$project$Chord$minorMajorSeventh;
		case 7:
			return author$project$Chord$minorSeventh;
		case 8:
			return author$project$Chord$augmentedMajorSeventh;
		case 9:
			return author$project$Chord$augmentedSeventh;
		case 10:
			return author$project$Chord$halfDiminishedSeventh;
		case 11:
			return author$project$Chord$diminishedSeventh;
		default:
			return author$project$Chord$seventhFlatFive;
	}
};
var author$project$Chord$tonesCount = function (q) {
	switch (q) {
		case 0:
			return 3;
		case 1:
			return 3;
		case 2:
			return 3;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 4;
		case 6:
			return 4;
		case 7:
			return 4;
		case 8:
			return 4;
		case 9:
			return 4;
		case 10:
			return 4;
		case 11:
			return 4;
		default:
			return 4;
	}
};
var author$project$GuitarChord$noNotes = _List_fromArray(
	[elm$core$Maybe$Nothing, elm$core$Maybe$Nothing, elm$core$Maybe$Nothing, elm$core$Maybe$Nothing, elm$core$Maybe$Nothing, elm$core$Maybe$Nothing]);
var author$project$GuitarChord$intervalFromIndex = F2(
	function (intervals, index) {
		if (index.$ === 1) {
			return elm$core$Maybe$Nothing;
		} else {
			var idx = index.a;
			return A2(elm_community$list_extra$List$Extra$getAt, idx, intervals);
		}
	});
var author$project$GuitarChord$nextIntervalIndex = F3(
	function (interval, max, index) {
		if (index.$ === 1) {
			return elm$core$Maybe$Nothing;
		} else {
			var idx = index.a;
			return elm$core$Maybe$Just(
				A2(elm$core$Basics$modBy, max, idx + interval));
		}
	});
var author$project$GuitarChord$takeInversion = F3(
	function (inversion, intervals, _n0) {
		var indices = _n0.a;
		var numNotes = _n0.b;
		var intervalIndices = A2(
			elm$core$List$map,
			A2(author$project$GuitarChord$nextIntervalIndex, inversion, numNotes),
			indices);
		return A2(
			elm$core$List$map,
			author$project$GuitarChord$intervalFromIndex(intervals),
			intervalIndices);
	});
var author$project$GuitarChord$chordQualityIntervals = F3(
	function (stringSet, quality, inversion) {
		var intervals = author$project$Chord$qualityToIntervals(quality);
		var indices = function () {
			switch (stringSet) {
				case 0:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								elm$core$Maybe$Just(0),
								elm$core$Maybe$Just(2),
								elm$core$Maybe$Just(3),
								elm$core$Maybe$Just(1),
								elm$core$Maybe$Nothing,
								elm$core$Maybe$Nothing
							]),
						author$project$Chord$tonesCount(quality));
				case 1:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								elm$core$Maybe$Nothing,
								elm$core$Maybe$Just(0),
								elm$core$Maybe$Just(2),
								elm$core$Maybe$Just(3),
								elm$core$Maybe$Just(1),
								elm$core$Maybe$Nothing
							]),
						author$project$Chord$tonesCount(quality));
				case 2:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								elm$core$Maybe$Nothing,
								elm$core$Maybe$Nothing,
								elm$core$Maybe$Just(0),
								elm$core$Maybe$Just(2),
								elm$core$Maybe$Just(3),
								elm$core$Maybe$Just(1)
							]),
						author$project$Chord$tonesCount(quality));
				case 3:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								elm$core$Maybe$Just(0),
								elm$core$Maybe$Nothing,
								elm$core$Maybe$Just(3),
								elm$core$Maybe$Just(1),
								elm$core$Maybe$Just(2),
								elm$core$Maybe$Nothing
							]),
						author$project$Chord$tonesCount(quality));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								elm$core$Maybe$Nothing,
								elm$core$Maybe$Just(0),
								elm$core$Maybe$Nothing,
								elm$core$Maybe$Just(3),
								elm$core$Maybe$Just(1),
								elm$core$Maybe$Just(2)
							]),
						author$project$Chord$tonesCount(quality));
			}
		}();
		var _n0 = _Utils_Tuple2(indices, intervals);
		if (!_n0.b.b) {
			return author$project$GuitarChord$noNotes;
		} else {
			if (!_n0.a.a.b) {
				var _n1 = _n0.a;
				return author$project$GuitarChord$noNotes;
			} else {
				return A3(author$project$GuitarChord$takeInversion, inversion, intervals, indices);
			}
		}
	});
var author$project$Interval$fifthIntervals = _List_fromArray(
	[14, 12, 17]);
var author$project$Interval$rootIntervals = _List_fromArray(
	[0]);
var author$project$Interval$AugmentedSeventh = 25;
var author$project$Interval$seventhIntervals = _List_fromArray(
	[20, 22, 19, 25]);
var author$project$Interval$AugmentedThird = 11;
var author$project$Interval$DiminishedThird = 5;
var author$project$Interval$thirdIntervals = _List_fromArray(
	[5, 6, 8, 11]);
var elm$core$Basics$not = _Basics_not;
var author$project$Fretboard$noteClassList = F3(
	function (model, stringNum, fretNum) {
		var rootTone = function () {
			var _n6 = model.u;
			switch (_n6) {
				case 3:
					return elm$core$Maybe$Just(
						author$project$Music$toTone(model.S.aF));
				case 2:
					var _n7 = model.am.ad;
					if (_n7.$ === 1) {
						return elm$core$Maybe$Nothing;
					} else {
						var answer = _n7.a;
						return elm$core$Maybe$Just(
							author$project$Music$toTone(answer.aF));
					}
				default:
					return elm$core$Maybe$Nothing;
			}
		}();
		var chordMode = author$project$Fretboard$isChordMode(model);
		var chordIntervals = function () {
			var _n4 = model.u;
			switch (_n4) {
				case 2:
					var _n5 = model.am.ad;
					if (_n5.$ === 1) {
						return elm$core$Maybe$Nothing;
					} else {
						var answer = _n5.a;
						return elm$core$Maybe$Just(
							A3(author$project$GuitarChord$chordQualityIntervals, answer.aL, answer.aB, answer.aq));
					}
				case 3:
					return elm$core$Maybe$Just(
						A3(author$project$GuitarChord$chordQualityIntervals, model.S.aL, model.S.aB, model.S.aq));
				default:
					return elm$core$Maybe$Nothing;
			}
		}();
		var chordClasses = function () {
			var _n1 = _Utils_Tuple2(chordIntervals, rootTone);
			if (_n1.a.$ === 1) {
				var _n2 = _n1.a;
				return _List_Nil;
			} else {
				if (_n1.b.$ === 1) {
					var _n3 = _n1.b;
					return _List_Nil;
				} else {
					var ci = _n1.a.a;
					var rt = _n1.b.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'root',
							chordMode && A5(author$project$Fretboard$isChordTone, rt, author$project$Interval$rootIntervals, ci, stringNum, fretNum)),
							_Utils_Tuple2(
							'fifth',
							chordMode && A5(author$project$Fretboard$isChordTone, rt, author$project$Interval$fifthIntervals, ci, stringNum, fretNum)),
							_Utils_Tuple2(
							'third',
							chordMode && A5(author$project$Fretboard$isChordTone, rt, author$project$Interval$thirdIntervals, ci, stringNum, fretNum)),
							_Utils_Tuple2(
							'seventh',
							chordMode && A5(author$project$Fretboard$isChordTone, rt, author$project$Interval$seventhIntervals, ci, stringNum, fretNum))
						]);
				}
			}
		}();
		var highlightClasses = _Utils_ap(
			chordClasses,
			_List_fromArray(
				[
					_Utils_Tuple2(
					'selected',
					(!chordMode) && A3(author$project$Fretboard$isSelected, model, stringNum, fretNum)),
					_Utils_Tuple2(
					'chord-octave',
					chordMode && A3(author$project$Fretboard$isOctave, model, stringNum, fretNum)),
					_Utils_Tuple2(
					'octave',
					(!chordMode) && A3(author$project$Fretboard$isOctave, model, stringNum, fretNum))
				]));
		var isHighlighted = A2(
			elm$core$List$any,
			function (_n0) {
				var visible = _n0.b;
				return visible;
			},
			highlightClasses);
		return _Utils_ap(
			highlightClasses,
			_List_fromArray(
				[
					_Utils_Tuple2('clickable', !model.u),
					_Utils_Tuple2('highlighted-note', isHighlighted)
				]));
	});
var author$project$Fretboard$fretCount = 12;
var author$project$Guitar$markerFrets = _List_fromArray(
	[1, 3, 5, 7, 9, 12, 15, 17, 19, 21, 24]);
var author$project$Guitar$isMarkerFret = F2(
	function (fretNum, stringNum) {
		return (stringNum === 3) && A2(elm$core$List$member, fretNum, author$project$Guitar$markerFrets);
	});
var author$project$Msg$GuitarNoteClicked = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var author$project$Msg$NoOp = {$: 3};
var elm$core$String$concat = function (strings) {
	return A2(elm$core$String$join, '', strings);
};
var elm$core$String$fromFloat = _String_fromNumber;
var elm$json$Json$Decode$map = _Json_map1;
var elm$json$Json$Decode$map2 = _Json_map2;
var elm$json$Json$Decode$succeed = _Json_succeed;
var elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var elm$html$Html$div = _VirtualDom_node('div');
var elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var elm$html$Html$text = elm$virtual_dom$VirtualDom$text;
var elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			elm$json$Json$Encode$string(string));
	});
var elm$html$Html$Attributes$class = elm$html$Html$Attributes$stringProperty('className');
var elm$html$Html$Attributes$classList = function (classes) {
	return elm$html$Html$Attributes$class(
		A2(
			elm$core$String$join,
			' ',
			A2(
				elm$core$List$map,
				elm$core$Tuple$first,
				A2(elm$core$List$filter, elm$core$Tuple$second, classes))));
};
var elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var elm$html$Html$Attributes$style = elm$virtual_dom$VirtualDom$style;
var elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			elm$virtual_dom$VirtualDom$on,
			event,
			elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var elm$html$Html$Events$onClick = function (msg) {
	return A2(
		elm$html$Html$Events$on,
		'click',
		elm$json$Json$Decode$succeed(msg));
};
var author$project$Fretboard$renderFrets = F2(
	function (model, stringNum) {
		var stringThickness = function () {
			var scale = 20;
			var minStringSize = 0.3;
			return minStringSize + (stringNum / scale);
		}();
		var selectedNoteName = A2(
			elm$core$Maybe$withDefault,
			'X',
			elm$core$List$head(
				A2(elm$core$String$split, '/', model.aI.a4)));
		var noteText = function (fretNum) {
			return ((!model.u) && A3(author$project$Fretboard$isSelected, model, stringNum, fretNum)) ? _List_fromArray(
				[
					A2(
					elm$html$Html$div,
					_List_fromArray(
						[
							elm$html$Html$Attributes$class('selected-note-name')
						]),
					_List_fromArray(
						[
							elm$html$Html$text(selectedNoteName)
						]))
				]) : _List_Nil;
		};
		var fretWidth = function (fretNum) {
			var scale = 2;
			var reverseFretNum = (author$project$Fretboard$fretCount + 1) - fretNum;
			var minFretSize = 4;
			return minFretSize + (reverseFretNum / scale);
		};
		var fretOnClick = function (fretNum) {
			return (!model.u) ? elm$html$Html$Events$onClick(
				A2(author$project$Msg$GuitarNoteClicked, stringNum, fretNum)) : elm$html$Html$Events$onClick(author$project$Msg$NoOp);
		};
		var floatToCssRemString = function (_float) {
			return elm$core$String$concat(
				_List_fromArray(
					[
						elm$core$String$fromFloat(_float),
						'rem'
					]));
		};
		var fretWidthInRems = function (fretNum) {
			return floatToCssRemString(
				fretWidth(fretNum));
		};
		var stringThicknessInRems = floatToCssRemString(stringThickness);
		var renderFret = function (fretNum) {
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('fret', true),
								_Utils_Tuple2(
								'fret-marker',
								A2(author$project$Guitar$isMarkerFret, fretNum, stringNum))
							])),
						A2(
						elm$html$Html$Attributes$style,
						'width',
						fretWidthInRems(fretNum))
					]),
				_List_fromArray(
					[
						A2(
						elm$html$Html$div,
						_List_fromArray(
							[
								elm$html$Html$Attributes$class('string-line'),
								elm$html$Html$Attributes$classList(
								A3(author$project$Fretboard$noteClassList, model, stringNum, fretNum)),
								A2(elm$html$Html$Attributes$style, 'height', stringThicknessInRems),
								fretOnClick(fretNum)
							]),
						noteText(fretNum))
					]));
		};
		return A2(
			elm$core$List$map,
			renderFret,
			A2(elm$core$List$range, 1, author$project$Fretboard$fretCount));
	});
var author$project$Fretboard$renderStringName = F2(
	function (model, stringNum) {
		return A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('string-container')
				]),
			_List_fromArray(
				[
					A2(
					elm$html$Html$div,
					_List_fromArray(
						[
							elm$html$Html$Attributes$class('string-name'),
							elm$html$Html$Attributes$classList(
							A3(author$project$Fretboard$noteClassList, model, stringNum, 0)),
							elm$html$Html$Events$onClick(
							A2(author$project$Msg$GuitarNoteClicked, stringNum, 0))
						]),
					_List_fromArray(
						[
							elm$html$Html$text(
							author$project$Guitar$getGuitarStringName(stringNum))
						])),
					A2(
					elm$html$Html$div,
					_List_fromArray(
						[
							elm$html$Html$Attributes$class('string')
						]),
					A2(author$project$Fretboard$renderFrets, model, stringNum))
				]));
	});
var author$project$Fretboard$renderStrings = function (model) {
	return A2(
		elm$core$List$map,
		author$project$Fretboard$renderStringName(model),
		A2(elm$core$List$range, 1, author$project$Fretboard$stringCount));
};
var author$project$Fretboard$render = function (model) {
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('fretboard-container')
			]),
		_List_fromArray(
			[
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('fretboard')
					]),
				author$project$Fretboard$renderStrings(model))
			]));
};
var author$project$Game$FindNotes = 4;
var author$project$Game$GuessNotes = 1;
var author$project$Msg$GuessChordReset = {$: 12};
var elm$html$Html$button = _VirtualDom_node('button');
var author$project$GuessChordGameControls$renderFeedback = function (model) {
	var _n0 = model.am.ao;
	switch (_n0) {
		case 1:
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('game-feedback incomplete')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Select Chord Properties')
					]));
		case 2:
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('game-feedback incorrect')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('WRONG!')
					]));
		default:
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('game-feedback correct')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Correct!'),
						A2(
						elm$html$Html$div,
						_List_fromArray(
							[
								elm$html$Html$Attributes$class('reset-button')
							]),
						_List_fromArray(
							[
								A2(
								elm$html$Html$button,
								_List_fromArray(
									[
										elm$html$Html$Events$onClick(author$project$Msg$GuessChordReset)
									]),
								_List_fromArray(
									[
										elm$html$Html$text('Try Another')
									]))
							]))
					]));
	}
};
var author$project$Msg$GuessChordInversionButtonClicked = function (a) {
	return {$: 16, a: a};
};
var elm$core$Basics$neq = _Utils_notEqual;
var elm$html$Html$h3 = _VirtualDom_node('h3');
var author$project$GuessChordGameControls$renderInversionButtons = F2(
	function (selectedInversion, answer) {
		var inversions = _List_fromArray(
			[
				_Utils_Tuple2(0, 'Root'),
				_Utils_Tuple2(1, 'First'),
				_Utils_Tuple2(2, 'Second'),
				_Utils_Tuple2(3, 'Third')
			]);
		var classes = function (inversion) {
			return elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'correct',
						function () {
							var _n1 = _Utils_Tuple2(selectedInversion, answer);
							if (_n1.b.$ === 1) {
								var _n2 = _n1.b;
								return false;
							} else {
								if (_n1.a.$ === 1) {
									var _n3 = _n1.a;
									return false;
								} else {
									var si = _n1.a.a;
									var ans = _n1.b.a;
									return _Utils_eq(si, inversion) && _Utils_eq(si, ans.aq);
								}
							}
						}()),
						_Utils_Tuple2(
						'incorrect',
						function () {
							var _n4 = _Utils_Tuple2(selectedInversion, answer);
							if (_n4.b.$ === 1) {
								var _n5 = _n4.b;
								return false;
							} else {
								if (_n4.a.$ === 1) {
									var _n6 = _n4.a;
									return false;
								} else {
									var si = _n4.a.a;
									var ans = _n4.b.a;
									return _Utils_eq(si, inversion) && (!_Utils_eq(si, ans.aq));
								}
							}
						}())
					]));
		};
		var renderInversionButton = function (_n0) {
			var inversion = _n0.a;
			var inversionName = _n0.b;
			return A2(
				elm$html$Html$button,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('quality-button'),
						elm$html$Html$Events$onClick(
						author$project$Msg$GuessChordInversionButtonClicked(inversion)),
						classes(inversion)
					]),
				_List_fromArray(
					[
						elm$html$Html$text(inversionName)
					]));
		};
		var inversionButtons = A2(elm$core$List$map, renderInversionButton, inversions);
		return A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('inversion-buttons')
				]),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						elm$html$Html$h3,
						_List_Nil,
						_List_fromArray(
							[
								elm$html$Html$text('Chord Inversion')
							]))
					]),
				inversionButtons));
	});
var elm$html$Html$span = _VirtualDom_node('span');
var author$project$GuessChordGameControls$renderLegend = A2(
	elm$html$Html$div,
	_List_fromArray(
		[
			elm$html$Html$Attributes$class('chord-tone-legend')
		]),
	_List_fromArray(
		[
			A2(
			elm$html$Html$h3,
			_List_Nil,
			_List_fromArray(
				[
					elm$html$Html$text('Chord Tone Legend')
				])),
			A2(
			elm$html$Html$span,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('root')
				]),
			_List_fromArray(
				[
					elm$html$Html$text('Root')
				])),
			A2(
			elm$html$Html$span,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('third')
				]),
			_List_fromArray(
				[
					elm$html$Html$text('Third')
				])),
			A2(
			elm$html$Html$span,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('fifth')
				]),
			_List_fromArray(
				[
					elm$html$Html$text('Fifth')
				])),
			A2(
			elm$html$Html$span,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('seventh')
				]),
			_List_fromArray(
				[
					elm$html$Html$text('Seventh')
				]))
		]));
var author$project$Msg$GuessChordRootButtonClicked = function (a) {
	return {$: 15, a: a};
};
var elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2(elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var elm$core$List$takeTailRec = F2(
	function (n, list) {
		return elm$core$List$reverse(
			A3(elm$core$List$takeReverse, n, list, _List_Nil));
	});
var elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _n0 = _Utils_Tuple2(n, list);
			_n0$1:
			while (true) {
				_n0$5:
				while (true) {
					if (!_n0.b.b) {
						return list;
					} else {
						if (_n0.b.b.b) {
							switch (_n0.a) {
								case 1:
									break _n0$1;
								case 2:
									var _n2 = _n0.b;
									var x = _n2.a;
									var _n3 = _n2.b;
									var y = _n3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_n0.b.b.b.b) {
										var _n4 = _n0.b;
										var x = _n4.a;
										var _n5 = _n4.b;
										var y = _n5.a;
										var _n6 = _n5.b;
										var z = _n6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _n0$5;
									}
								default:
									if (_n0.b.b.b.b && _n0.b.b.b.b.b) {
										var _n7 = _n0.b;
										var x = _n7.a;
										var _n8 = _n7.b;
										var y = _n8.a;
										var _n9 = _n8.b;
										var z = _n9.a;
										var _n10 = _n9.b;
										var w = _n10.a;
										var tl = _n10.b;
										return (ctr > 1000) ? A2(
											elm$core$List$cons,
											x,
											A2(
												elm$core$List$cons,
												y,
												A2(
													elm$core$List$cons,
													z,
													A2(
														elm$core$List$cons,
														w,
														A2(elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											elm$core$List$cons,
											x,
											A2(
												elm$core$List$cons,
												y,
												A2(
													elm$core$List$cons,
													z,
													A2(
														elm$core$List$cons,
														w,
														A3(elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _n0$5;
									}
							}
						} else {
							if (_n0.a === 1) {
								break _n0$1;
							} else {
								break _n0$5;
							}
						}
					}
				}
				return list;
			}
			var _n1 = _n0.b;
			var x = _n1.a;
			return _List_fromArray(
				[x]);
		}
	});
var elm$core$List$take = F2(
	function (n, list) {
		return A3(elm$core$List$takeFast, 0, n, list);
	});
var elm$core$String$contains = _String_contains;
var author$project$GuessChordGameControls$renderNoteButtons = F2(
	function (rootNote, answer) {
		var normalizedNoteName = function (note) {
			return A2(
				elm$core$Maybe$withDefault,
				'',
				elm$core$List$head(
					A2(elm$core$String$split, '/', note)));
		};
		var classes = function (note) {
			return elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'accidental',
						A2(elm$core$String$contains, '/', note)),
						_Utils_Tuple2(
						'correct',
						function () {
							var _n0 = _Utils_Tuple2(rootNote, answer);
							if (_n0.b.$ === 1) {
								var _n1 = _n0.b;
								return false;
							} else {
								if (_n0.a.$ === 1) {
									var _n2 = _n0.a;
									return false;
								} else {
									var rn = _n0.a.a;
									var ans = _n0.b.a;
									return _Utils_eq(rn, note) && _Utils_eq(ans.aF, rn);
								}
							}
						}()),
						_Utils_Tuple2(
						'incorrect',
						function () {
							var _n3 = _Utils_Tuple2(rootNote, answer);
							if (_n3.b.$ === 1) {
								var _n4 = _n3.b;
								return false;
							} else {
								if (_n3.a.$ === 1) {
									var _n5 = _n3.a;
									return false;
								} else {
									var rn = _n3.a.a;
									var ans = _n3.b.a;
									return _Utils_eq(rn, note) && (!_Utils_eq(ans.aF, rn));
								}
							}
						}())
					]));
		};
		var renderNoteButton = function (note) {
			return A2(
				elm$html$Html$button,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('note-button'),
						elm$html$Html$Events$onClick(
						author$project$Msg$GuessChordRootButtonClicked(note)),
						classes(note)
					]),
				_List_fromArray(
					[
						elm$html$Html$text(
						normalizedNoteName(note))
					]));
		};
		var noteButtons = function () {
			var tail = A2(elm$core$List$take, 3, author$project$Music$notes);
			var head = A2(elm$core$List$drop, 3, author$project$Music$notes);
			var chromatic = _Utils_ap(head, tail);
			return A2(elm$core$List$map, renderNoteButton, chromatic);
		}();
		return A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('note-buttons')
				]),
			noteButtons);
	});
var author$project$Msg$GuessChordQualityButtonClicked = function (a) {
	return {$: 14, a: a};
};
var author$project$GuessChordGameControls$renderQualityButtons = F2(
	function (selectedQuality, answer) {
		var classes = function (quality) {
			return elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'correct',
						function () {
							var _n1 = _Utils_Tuple2(selectedQuality, answer);
							if (_n1.b.$ === 1) {
								var _n2 = _n1.b;
								return false;
							} else {
								if (_n1.a.$ === 1) {
									var _n3 = _n1.a;
									return false;
								} else {
									var sq = _n1.a.a;
									var ans = _n1.b.a;
									return _Utils_eq(sq, quality) && _Utils_eq(sq, ans.aB);
								}
							}
						}()),
						_Utils_Tuple2(
						'incorrect',
						function () {
							var _n4 = _Utils_Tuple2(selectedQuality, answer);
							if (_n4.b.$ === 1) {
								var _n5 = _n4.b;
								return false;
							} else {
								if (_n4.a.$ === 1) {
									var _n6 = _n4.a;
									return false;
								} else {
									var sq = _n4.a.a;
									var ans = _n4.b.a;
									return _Utils_eq(sq, quality) && (!_Utils_eq(sq, ans.aB));
								}
							}
						}())
					]));
		};
		var renderQualityButton = function (_n0) {
			var quality = _n0.a;
			var qualityName = _n0.b;
			return A2(
				elm$html$Html$button,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('quality-button'),
						elm$html$Html$Events$onClick(
						author$project$Msg$GuessChordQualityButtonClicked(quality)),
						classes(quality)
					]),
				_List_fromArray(
					[
						elm$html$Html$text(qualityName)
					]));
		};
		var qualityButtons = A2(elm$core$List$map, renderQualityButton, author$project$GuessChordGame$guessChordQualities);
		return A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('quality-buttons')
				]),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						elm$html$Html$h3,
						_List_Nil,
						_List_fromArray(
							[
								elm$html$Html$text('Chord Quality')
							]))
					]),
				qualityButtons));
	});
var author$project$GuitarChord$stringSetLabel = function (stringSet) {
	switch (stringSet) {
		case 0:
			return '1|4';
		case 1:
			return '2|4';
		case 2:
			return '3|4';
		case 3:
			return '1|B4';
		default:
			return '2|B4';
	}
};
var author$project$Msg$GuessChordStringSetButtonClicked = function (a) {
	return {$: 13, a: a};
};
var elm$html$Html$img = _VirtualDom_node('img');
var elm$html$Html$Attributes$src = function (url) {
	return A2(
		elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var author$project$GuessChordGameControls$renderStringSetButtons = F2(
	function (selectedStringSet, answer) {
		var classes = function (stringSet) {
			return elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'correct',
						function () {
							var _n0 = _Utils_Tuple2(selectedStringSet, answer);
							if (_n0.a.$ === 1) {
								var _n1 = _n0.a;
								return false;
							} else {
								if (_n0.b.$ === 1) {
									var _n2 = _n0.b;
									return false;
								} else {
									var sss = _n0.a.a;
									var ans = _n0.b.a;
									return _Utils_eq(sss, stringSet) && _Utils_eq(sss, ans.aL);
								}
							}
						}()),
						_Utils_Tuple2(
						'incorrect',
						function () {
							var _n3 = _Utils_Tuple2(selectedStringSet, answer);
							if (_n3.a.$ === 1) {
								var _n4 = _n3.a;
								return false;
							} else {
								if (_n3.b.$ === 1) {
									var _n5 = _n3.b;
									return false;
								} else {
									var sss = _n3.a.a;
									var ans = _n3.b.a;
									return _Utils_eq(sss, stringSet) && (!_Utils_eq(sss, ans.aL));
								}
							}
						}())
					]));
		};
		var renderStringSetButton = function (stringSet) {
			return A2(
				elm$html$Html$button,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('string-set-button'),
						elm$html$Html$Events$onClick(
						author$project$Msg$GuessChordStringSetButtonClicked(stringSet)),
						classes(stringSet)
					]),
				_List_fromArray(
					[
						A2(
						elm$html$Html$img,
						_List_fromArray(
							[
								elm$html$Html$Attributes$src(
								'string-sets/' + (author$project$GuitarChord$stringSetLabel(stringSet) + '.svg'))
							]),
						_List_Nil)
					]));
		};
		var stringSetButtons = A2(elm$core$List$map, renderStringSetButton, author$project$GuitarChord$stringSets);
		return A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('string-set-buttons')
				]),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						elm$html$Html$h3,
						_List_Nil,
						_List_fromArray(
							[
								elm$html$Html$text('String Set')
							]))
					]),
				stringSetButtons));
	});
var author$project$GuessChordGameControls$render = function (model) {
	var guess = model.am.al;
	var answer = model.am.ad;
	return _List_fromArray(
		[
			A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('show-chord')
				]),
			_List_fromArray(
				[
					author$project$GuessChordGameControls$renderFeedback(model),
					author$project$GuessChordGameControls$renderLegend,
					A2(author$project$GuessChordGameControls$renderStringSetButtons, guess.aL, answer),
					A2(author$project$GuessChordGameControls$renderQualityButtons, guess.aB, answer),
					A2(author$project$GuessChordGameControls$renderInversionButtons, guess.aq, answer),
					A2(author$project$GuessChordGameControls$renderNoteButtons, guess.aF, answer)
				]))
		]);
};
var author$project$GuessNoteGameControls$renderFeedback = function (model) {
	var _n0 = model.an.ao;
	switch (_n0) {
		case 1:
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('game-feedback correct')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Correct!')
					]));
		case 2:
			return A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('game-feedback incorrect')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('WRONG!')
					]));
		default:
			return A2(elm$html$Html$div, _List_Nil, _List_Nil);
	}
};
var author$project$Msg$GuessNoteButtonClicked = function (a) {
	return {$: 1, a: a};
};
var elm$json$Json$Encode$bool = _Json_wrap;
var elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			elm$json$Json$Encode$bool(bool));
	});
var elm$html$Html$Attributes$disabled = elm$html$Html$Attributes$boolProperty('disabled');
var author$project$GuessNoteGameControls$renderNoteButtons = function (guesses) {
	var normalizedNoteName = function (note) {
		return A2(
			elm$core$Maybe$withDefault,
			'',
			elm$core$List$head(
				A2(elm$core$String$split, '/', note)));
	};
	var disablePreviousGuess = function (note) {
		return elm$html$Html$Attributes$disabled(
			A2(elm$core$List$member, note, guesses));
	};
	var classes = function (note) {
		return elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'accidental',
					A2(elm$core$String$contains, '/', note))
				]));
	};
	var renderNoteButton = function (note) {
		return A2(
			elm$html$Html$button,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('note-button'),
					disablePreviousGuess(note),
					elm$html$Html$Events$onClick(
					author$project$Msg$GuessNoteButtonClicked(note)),
					classes(note)
				]),
			_List_fromArray(
				[
					elm$html$Html$text(
					normalizedNoteName(note))
				]));
	};
	var noteButtons = function () {
		var tail = A2(elm$core$List$take, 3, author$project$Music$notes);
		var head = A2(elm$core$List$drop, 3, author$project$Music$notes);
		var chromatic = _Utils_ap(head, tail);
		return A2(elm$core$List$map, renderNoteButton, chromatic);
	}();
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('note-buttons')
			]),
		noteButtons);
};
var author$project$GuessNoteGameControls$render = function (model) {
	return _List_fromArray(
		[
			author$project$GuessNoteGameControls$renderFeedback(model),
			author$project$GuessNoteGameControls$renderNoteButtons(model.an.a_)
		]);
};
var author$project$Msg$ShowOctavesChanged = function (a) {
	return {$: 7, a: a};
};
var author$project$SelectedNote$render = function (model) {
	var selectedNote = model.aI;
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('selected-note-info')
			]),
		_List_fromArray(
			[
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('title')
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Selected Note:')
					])),
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('note-name')
					]),
				_List_fromArray(
					[
						elm$html$Html$text(selectedNote.a4)
					])),
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('string-name')
					]),
				_List_fromArray(
					[
						A2(
						elm$html$Html$span,
						_List_fromArray(
							[
								elm$html$Html$Attributes$class('label')
							]),
						_List_fromArray(
							[
								elm$html$Html$text('String:')
							])),
						elm$html$Html$text(selectedNote.aJ)
					])),
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('string-num')
					]),
				_List_fromArray(
					[
						A2(
						elm$html$Html$span,
						_List_fromArray(
							[
								elm$html$Html$Attributes$class('label')
							]),
						_List_fromArray(
							[
								elm$html$Html$text('String #:')
							])),
						elm$html$Html$text(
						elm$core$String$fromInt(selectedNote.aK))
					])),
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('fret-num')
					]),
				_List_fromArray(
					[
						A2(
						elm$html$Html$span,
						_List_fromArray(
							[
								elm$html$Html$Attributes$class('label')
							]),
						_List_fromArray(
							[
								elm$html$Html$text('Fret #:')
							])),
						elm$html$Html$text(
						elm$core$String$fromInt(selectedNote.aj))
					])),
				A2(
				elm$html$Html$div,
				_List_fromArray(
					[
						elm$html$Html$Attributes$class('pitch-notation')
					]),
				_List_fromArray(
					[
						A2(
						elm$html$Html$span,
						_List_fromArray(
							[
								elm$html$Html$Attributes$class('label')
							]),
						_List_fromArray(
							[
								elm$html$Html$text('SPN:')
							])),
						elm$html$Html$text(
						author$project$Music$pitchNotationToStr(selectedNote.Y))
					]))
			]));
};
var elm$html$Html$input = _VirtualDom_node('input');
var elm$html$Html$label = _VirtualDom_node('label');
var elm$html$Html$Attributes$type_ = elm$html$Html$Attributes$stringProperty('type');
var author$project$Toggle$render = F2(
	function (labelText, attributes) {
		var inputAttributes = _Utils_ap(
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('toggle-state'),
					elm$html$Html$Attributes$type_('checkbox')
				]),
			attributes);
		return A2(
			elm$html$Html$label,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('toggle-label')
				]),
			_List_fromArray(
				[
					A2(
					elm$html$Html$div,
					_List_fromArray(
						[
							elm$html$Html$Attributes$class('toggle')
						]),
					_List_fromArray(
						[
							A2(elm$html$Html$input, inputAttributes, _List_Nil),
							A2(
							elm$html$Html$div,
							_List_fromArray(
								[
									elm$html$Html$Attributes$class('toggle-inner')
								]),
							_List_fromArray(
								[
									A2(
									elm$html$Html$div,
									_List_fromArray(
										[
											elm$html$Html$Attributes$class('indicator')
										]),
									_List_Nil)
								])),
							A2(
							elm$html$Html$div,
							_List_fromArray(
								[
									elm$html$Html$Attributes$class('active-bg')
								]),
							_List_Nil)
						])),
					A2(
					elm$html$Html$div,
					_List_fromArray(
						[
							elm$html$Html$Attributes$class('label-text')
						]),
					_List_fromArray(
						[
							elm$html$Html$text(labelText)
						]))
				]));
	});
var elm$html$Html$Attributes$checked = elm$html$Html$Attributes$boolProperty('checked');
var elm$json$Json$Decode$field = _Json_decodeField;
var elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3(elm$core$List$foldr, elm$json$Json$Decode$field, decoder, fields);
	});
var elm$json$Json$Decode$bool = _Json_decodeBool;
var elm$html$Html$Events$targetChecked = A2(
	elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	elm$json$Json$Decode$bool);
var elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		elm$html$Html$Events$on,
		'change',
		A2(elm$json$Json$Decode$map, tagger, elm$html$Html$Events$targetChecked));
};
var author$project$LearnGameControls$render = function (model) {
	return _List_fromArray(
		[
			A2(
			author$project$Toggle$render,
			'Show Octaves',
			_List_fromArray(
				[
					elm$html$Html$Events$onCheck(author$project$Msg$ShowOctavesChanged),
					elm$html$Html$Attributes$checked(model.bb)
				])),
			author$project$SelectedNote$render(model)
		]);
};
var author$project$Msg$ShowChordInversionButtonClicked = function (a) {
	return {$: 11, a: a};
};
var author$project$ShowChordControls$renderInversionButtons = function (selectedInversion) {
	var inversions = _List_fromArray(
		[
			_Utils_Tuple2(0, 'Root'),
			_Utils_Tuple2(1, 'First'),
			_Utils_Tuple2(2, 'Second'),
			_Utils_Tuple2(3, 'Third')
		]);
	var classes = function (inversion) {
		return elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'active',
					_Utils_eq(selectedInversion, inversion))
				]));
	};
	var renderInversionButton = function (_n0) {
		var inversion = _n0.a;
		var inversionName = _n0.b;
		return A2(
			elm$html$Html$button,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('quality-button'),
					elm$html$Html$Events$onClick(
					author$project$Msg$ShowChordInversionButtonClicked(inversion)),
					classes(inversion)
				]),
			_List_fromArray(
				[
					elm$html$Html$text(inversionName)
				]));
	};
	var inversionButtons = A2(elm$core$List$map, renderInversionButton, inversions);
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('inversion-buttons')
			]),
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					elm$html$Html$h3,
					_List_Nil,
					_List_fromArray(
						[
							elm$html$Html$text('Chord Inversion')
						]))
				]),
			inversionButtons));
};
var author$project$ShowChordControls$renderLegend = A2(
	elm$html$Html$div,
	_List_fromArray(
		[
			elm$html$Html$Attributes$class('chord-tone-legend')
		]),
	_List_fromArray(
		[
			A2(
			elm$html$Html$h3,
			_List_Nil,
			_List_fromArray(
				[
					elm$html$Html$text('Chord Tone Legend')
				])),
			A2(
			elm$html$Html$span,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('root')
				]),
			_List_fromArray(
				[
					elm$html$Html$text('Root')
				])),
			A2(
			elm$html$Html$span,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('third')
				]),
			_List_fromArray(
				[
					elm$html$Html$text('Third')
				])),
			A2(
			elm$html$Html$span,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('fifth')
				]),
			_List_fromArray(
				[
					elm$html$Html$text('Fifth')
				])),
			A2(
			elm$html$Html$span,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('seventh')
				]),
			_List_fromArray(
				[
					elm$html$Html$text('Seventh')
				]))
		]));
var author$project$Msg$ShowChordRootButtonClicked = function (a) {
	return {$: 10, a: a};
};
var author$project$ShowChordControls$renderNoteButtons = function (rootNote) {
	var normalizedNoteName = function (note) {
		return A2(
			elm$core$Maybe$withDefault,
			'',
			elm$core$List$head(
				A2(elm$core$String$split, '/', note)));
	};
	var classes = function (note) {
		return elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'accidental',
					A2(elm$core$String$contains, '/', note)),
					_Utils_Tuple2(
					'active',
					_Utils_eq(rootNote, note))
				]));
	};
	var renderNoteButton = function (note) {
		return A2(
			elm$html$Html$button,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('note-button'),
					elm$html$Html$Events$onClick(
					author$project$Msg$ShowChordRootButtonClicked(note)),
					classes(note)
				]),
			_List_fromArray(
				[
					elm$html$Html$text(
					normalizedNoteName(note))
				]));
	};
	var noteButtons = function () {
		var tail = A2(elm$core$List$take, 3, author$project$Music$notes);
		var head = A2(elm$core$List$drop, 3, author$project$Music$notes);
		var chromatic = _Utils_ap(head, tail);
		return A2(elm$core$List$map, renderNoteButton, chromatic);
	}();
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('note-buttons')
			]),
		noteButtons);
};
var author$project$Chord$AugmentedMajorSeventh = 8;
var author$project$Chord$AugmentedSeventh = 9;
var author$project$Chord$AugmentedTriad = 2;
var author$project$Chord$DiminishedTriad = 3;
var author$project$Chord$MajorTriad = 0;
var author$project$Chord$MinorMajorSeventh = 6;
var author$project$Chord$MinorTriad = 1;
var author$project$Chord$SeventhFlatFive = 12;
var author$project$Msg$ShowChordQualityButtonClicked = function (a) {
	return {$: 9, a: a};
};
var author$project$ShowChordControls$renderQualityButtons = function (selectedQuality) {
	var qualities = _List_fromArray(
		[
			_Utils_Tuple3(5, 'Δ', 'major-seventh'),
			_Utils_Tuple3(8, 'Δ#5', 'augmented-major-seventh'),
			_Utils_Tuple3(4, '7', 'seventh'),
			_Utils_Tuple3(12, '7b5', 'seventh-flat-five'),
			_Utils_Tuple3(9, '7#5', 'augmented-seventh'),
			_Utils_Tuple3(7, '-7', 'minor-seventh'),
			_Utils_Tuple3(6, '-Δ', 'minor-major-seventh'),
			_Utils_Tuple3(10, 'Ø7', 'half-diminished-seventh'),
			_Utils_Tuple3(11, 'O7', 'diminished-seventh'),
			_Utils_Tuple3(1, '-', 'minor'),
			_Utils_Tuple3(0, 'M', 'major'),
			_Utils_Tuple3(2, '#5', 'augmented'),
			_Utils_Tuple3(3, 'b5', 'diminished')
		]);
	var classes = function (quality) {
		return elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'active',
					_Utils_eq(selectedQuality, quality))
				]));
	};
	var renderQualityButton = function (_n0) {
		var quality = _n0.a;
		var qualityName = _n0.b;
		var qualityClass = _n0.c;
		return A2(
			elm$html$Html$button,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('quality-button'),
					elm$html$Html$Attributes$class(qualityClass),
					elm$html$Html$Events$onClick(
					author$project$Msg$ShowChordQualityButtonClicked(quality)),
					classes(quality)
				]),
			_List_fromArray(
				[
					elm$html$Html$text(qualityName)
				]));
	};
	var qualityButtons = A2(elm$core$List$map, renderQualityButton, qualities);
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('quality-buttons')
			]),
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					elm$html$Html$h3,
					_List_Nil,
					_List_fromArray(
						[
							elm$html$Html$text('Chord Quality')
						]))
				]),
			qualityButtons));
};
var author$project$Msg$ShowChordStringSetButtonClicked = function (a) {
	return {$: 8, a: a};
};
var author$project$ShowChordControls$renderStringSetButtons = function (selectedStringSet) {
	var classes = function (stringSet) {
		return elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'active',
					_Utils_eq(selectedStringSet, stringSet))
				]));
	};
	var renderStringSetButton = function (stringSet) {
		return A2(
			elm$html$Html$button,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('string-set-button'),
					elm$html$Html$Events$onClick(
					author$project$Msg$ShowChordStringSetButtonClicked(stringSet)),
					classes(stringSet)
				]),
			_List_fromArray(
				[
					A2(
					elm$html$Html$img,
					_List_fromArray(
						[
							elm$html$Html$Attributes$src(
							'string-sets/' + (author$project$GuitarChord$stringSetLabel(stringSet) + '.svg'))
						]),
					_List_Nil)
				]));
	};
	var stringSetButtons = A2(elm$core$List$map, renderStringSetButton, author$project$GuitarChord$stringSets);
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('string-set-buttons')
			]),
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					elm$html$Html$h3,
					_List_Nil,
					_List_fromArray(
						[
							elm$html$Html$text('String Set')
						]))
				]),
			stringSetButtons));
};
var author$project$ShowChordControls$render = function (model) {
	return _List_fromArray(
		[
			A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class('show-chord')
				]),
			_List_fromArray(
				[
					author$project$ShowChordControls$renderLegend,
					author$project$ShowChordControls$renderStringSetButtons(model.S.aL),
					author$project$ShowChordControls$renderQualityButtons(model.S.aB),
					author$project$ShowChordControls$renderInversionButtons(model.S.aq),
					author$project$ShowChordControls$renderNoteButtons(model.S.aF)
				]))
		]);
};
var author$project$GameControls$render = function (model) {
	var gameControls = function () {
		var _n0 = model.u;
		switch (_n0) {
			case 1:
				return author$project$GuessNoteGameControls$render(model);
			case 0:
				return author$project$LearnGameControls$render(model);
			case 2:
				return author$project$GuessChordGameControls$render(model);
			case 3:
				return author$project$ShowChordControls$render(model);
			default:
				return _List_fromArray(
					[
						A2(
						elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								elm$html$Html$text('COMING SOON...')
							]))
					]);
		}
	}();
	var classes = elm$html$Html$Attributes$classList(
		_List_fromArray(
			[
				_Utils_Tuple2('learn', !model.u),
				_Utils_Tuple2('guess-notes', model.u === 1),
				_Utils_Tuple2('find-notes', model.u === 4)
			]));
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('game-controls'),
				classes
			]),
		gameControls);
};
var author$project$Msg$ChangeGameMode = function (a) {
	return {$: 0, a: a};
};
var elm$html$Html$li = _VirtualDom_node('li');
var elm$html$Html$ul = _VirtualDom_node('ul');
var author$project$Menu$render = function (model) {
	var activeClass = function (mode) {
		return elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'active',
					_Utils_eq(model.u, mode))
				]));
	};
	return A2(
		elm$html$Html$ul,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('menu')
			]),
		_List_fromArray(
			[
				A2(
				elm$html$Html$li,
				_List_fromArray(
					[
						activeClass(0),
						elm$html$Html$Events$onClick(
						author$project$Msg$ChangeGameMode(0))
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Learn Notes')
					])),
				A2(
				elm$html$Html$li,
				_List_fromArray(
					[
						activeClass(1),
						elm$html$Html$Events$onClick(
						author$project$Msg$ChangeGameMode(1))
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Guess Notes')
					])),
				A2(
				elm$html$Html$li,
				_List_fromArray(
					[
						activeClass(2),
						elm$html$Html$Events$onClick(
						author$project$Msg$ChangeGameMode(2))
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Guess Chord')
					])),
				A2(
				elm$html$Html$li,
				_List_fromArray(
					[
						activeClass(3),
						elm$html$Html$Events$onClick(
						author$project$Msg$ChangeGameMode(3))
					]),
				_List_fromArray(
					[
						elm$html$Html$text('Show Chord')
					]))
			]));
};
var elm$html$Html$Attributes$alt = elm$html$Html$Attributes$stringProperty('alt');
var author$project$Header$render = function (model) {
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('header-container')
			]),
		_List_fromArray(
			[
				A2(
				elm$html$Html$img,
				_List_fromArray(
					[
						elm$html$Html$Attributes$alt('fretmaster logo'),
						elm$html$Html$Attributes$class('logo'),
						elm$html$Html$Attributes$src('logo.svg')
					]),
				_List_Nil),
				author$project$Menu$render(model)
			]));
};
var author$project$View$view = function (model) {
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class('main')
			]),
		_List_fromArray(
			[
				author$project$Header$render(model),
				author$project$Fretboard$render(model),
				author$project$GameControls$render(model)
			]));
};
var elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var elm$browser$Browser$Dom$NotFound = elm$core$Basics$identity;
var elm$core$Basics$never = function (_n0) {
	never:
	while (true) {
		var nvr = _n0;
		var $temp$_n0 = nvr;
		_n0 = $temp$_n0;
		continue never;
	}
};
var elm$core$Task$Perform = elm$core$Basics$identity;
var elm$core$Task$init = elm$core$Task$succeed(0);
var elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			elm$core$Task$andThen,
			function (a) {
				return elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			elm$core$Task$andThen,
			function (a) {
				return A2(
					elm$core$Task$andThen,
					function (b) {
						return elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var elm$core$Task$sequence = function (tasks) {
	return A3(
		elm$core$List$foldr,
		elm$core$Task$map2(elm$core$List$cons),
		elm$core$Task$succeed(_List_Nil),
		tasks);
};
var elm$core$Task$spawnCmd = F2(
	function (router, _n0) {
		var task = _n0;
		return _Scheduler_spawn(
			A2(
				elm$core$Task$andThen,
				elm$core$Platform$sendToApp(router),
				task));
	});
var elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			elm$core$Task$map,
			function (_n0) {
				return 0;
			},
			elm$core$Task$sequence(
				A2(
					elm$core$List$map,
					elm$core$Task$spawnCmd(router),
					commands)));
	});
var elm$core$Task$onSelfMsg = F3(
	function (_n0, _n1, _n2) {
		return elm$core$Task$succeed(0);
	});
var elm$core$Task$cmdMap = F2(
	function (tagger, _n0) {
		var task = _n0;
		return A2(elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager(elm$core$Task$init, elm$core$Task$onEffects, elm$core$Task$onSelfMsg, elm$core$Task$cmdMap);
var elm$core$Task$command = _Platform_leaf('Task');
var elm$core$Task$perform = F2(
	function (toMessage, task) {
		return elm$core$Task$command(
			A2(elm$core$Task$map, toMessage, task));
	});
var elm$core$String$length = _String_length;
var elm$core$String$slice = _String_slice;
var elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			elm$core$String$slice,
			n,
			elm$core$String$length(string),
			string);
	});
var elm$core$String$startsWith = _String_startsWith;
var elm$url$Url$Http = 0;
var elm$url$Url$Https = 1;
var elm$core$String$indexes = _String_indexes;
var elm$core$String$isEmpty = function (string) {
	return string === '';
};
var elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(elm$core$String$slice, 0, n, string);
	});
var elm$core$String$toInt = _String_toInt;
var elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {ai: fragment, ap: host, av: path, ax: port_, aA: protocol, aC: query};
	});
var elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if (elm$core$String$isEmpty(str) || A2(elm$core$String$contains, '@', str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, ':', str);
			if (!_n0.b) {
				return elm$core$Maybe$Just(
					A6(elm$url$Url$Url, protocol, str, elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_n0.b.b) {
					var i = _n0.a;
					var _n1 = elm$core$String$toInt(
						A2(elm$core$String$dropLeft, i + 1, str));
					if (_n1.$ === 1) {
						return elm$core$Maybe$Nothing;
					} else {
						var port_ = _n1;
						return elm$core$Maybe$Just(
							A6(
								elm$url$Url$Url,
								protocol,
								A2(elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return elm$core$Maybe$Nothing;
				}
			}
		}
	});
var elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '/', str);
			if (!_n0.b) {
				return A5(elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _n0.a;
				return A5(
					elm$url$Url$chompBeforePath,
					protocol,
					A2(elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '?', str);
			if (!_n0.b) {
				return A4(elm$url$Url$chompBeforeQuery, protocol, elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _n0.a;
				return A4(
					elm$url$Url$chompBeforeQuery,
					protocol,
					elm$core$Maybe$Just(
						A2(elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '#', str);
			if (!_n0.b) {
				return A3(elm$url$Url$chompBeforeFragment, protocol, elm$core$Maybe$Nothing, str);
			} else {
				var i = _n0.a;
				return A3(
					elm$url$Url$chompBeforeFragment,
					protocol,
					elm$core$Maybe$Just(
						A2(elm$core$String$dropLeft, i + 1, str)),
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$fromString = function (str) {
	return A2(elm$core$String$startsWith, 'http://', str) ? A2(
		elm$url$Url$chompAfterProtocol,
		0,
		A2(elm$core$String$dropLeft, 7, str)) : (A2(elm$core$String$startsWith, 'https://', str) ? A2(
		elm$url$Url$chompAfterProtocol,
		1,
		A2(elm$core$String$dropLeft, 8, str)) : elm$core$Maybe$Nothing);
};
var elm$browser$Browser$element = _Browser_element;
var elm$core$Basics$always = F2(
	function (a, _n0) {
		return a;
	});
var elm$core$Platform$Sub$batch = _Platform_batch;
var elm$core$Platform$Sub$none = elm$core$Platform$Sub$batch(_List_Nil);
var author$project$Main$main = elm$browser$Browser$element(
	{
		a1: function (_n0) {
			return author$project$Model$init;
		},
		bc: elm$core$Basics$always(elm$core$Platform$Sub$none),
		be: author$project$Update$update,
		bg: author$project$View$view
	});
_Platform_export({'Main':{'init':author$project$Main$main(
	elm$json$Json$Decode$succeed(0))(0)}});}(this));